<template>
  <div class="details-container" v-if="selectedWorkflow?.blueprint && !(selectedWorkflow?.events ?? []).length">
    <div class="content">
      <h3>Choose a Trigger</h3>
      <template v-for="list in triggersList" :key="list._id">
        <div
          class="saas_card hoverable mb-10"
          v-if="
            selectedWorkflow?.selectedTrigger === null ||
            (list.key != null && list.key === selectedWorkflow?.selectedTrigger) ||
            activateChangeTrigger
          "
        >
          <div class="row-contents">
            <div class="group-text-container" @click="selectTrigger(selectedWorkflow, list)">
              <div class="group-text">
                <span class="title">
                  {{ list?.name + ' ' + selectedWorkflowBlueprint?.name }}
                </span>
                <span class="muted-text">{{ list.details }}</span>
              </div>
            </div>
            <div class="metadata" @click="changeTrigger">
              <div class="controls">
                <EditOutlined v-if="selectedWorkflow?.selectedTrigger !== null" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { WorkflowActions } from '@/apps/workflow/shared/workflow.store';
import '@vue-js-cron/light/dist/light.css';
import { EditOutlined } from '@ant-design/icons-vue';

export default {
  props: ['workflows', 'selectedWorkflow', 'event', 'workflowId', 'selectedWorkflowBlueprint'],
  components: { EditOutlined },
  emits: ['openEventId', 'changedTrigger'],
  data() {
    return {
      eventBlueprintId: null,
      activateChangeTrigger: false,
      triggersList: [
        {
          _id: 1,
          key: 'CREATED',
          name: 'Create',
          details: 'Triggers when an Entry is Created.',
          icon: '',
        },
        {
          _id: 3,
          key: 'UPDATED',
          name: 'Update',
          details: 'Triggers when an Entry is Updated.',
          icon: '',
        },
        {
          _id: 4,
          key: 'DELETED',
          name: 'Delete',
          details: 'Triggers when an Entry is Deleted.',
          icon: '',
        },
        {
          _id: 5,
          key: 'RUN LOCALLY',
          name: 'Manual Jump Trigger',
          details: 'Use this to continue a workflow jump.',
          icon: '',
        },
        {
          _id: 6,
          key: 'TIMED',
          name: 'Time Trigger',
          details: 'Triggers event at specific time.',
          icon: '',
        },
      ],
    };
  },
  methods: {
    async selectTrigger(workflow, trigger) {
      this.activateChangeTrigger = false;
      workflow.selectedTrigger = trigger.key;
      await WorkflowActions.editSelectedWorkflow(workflow, {
        ...workflow,
      });
      this.$emit('changedTrigger', true);
    },
    changeTrigger() {
      this.activateChangeTrigger = true;
    },
  },
};
</script>
<style scoped></style>
