<template>
  <div>
    <div class="mb-10">
      Referenced blueprint:
      <strong>{{ currentlyReferencedBlueprint }}</strong>
    </div>
    <div style="margin: 10px 0px">
      <a-checkbox :checked="input.useDefaultValue" @change="toggleDefaultValue">Use a default value.</a-checkbox>
    </div>

    <div v-if="input.useDefaultValue" style="margin: 10px 0px">
      <label>Select a instance to use as default value</label>
      <a-select
        show-search
        placeholder="Choose instance"
        option-filter-prop="instance-name"
        style="width: 100%; display: block; margin-top: 5px; margin-bottom: 15px"
        v-model:value="input.instanceId"
      >
        <a-select-option v-for="instance in instanceList" :key="instance._id" :value="instance._id" :instance-name="instance.name">{{
          instance.name
        }}</a-select-option>
      </a-select>
    </div>
  </div>
</template>

<script>
import { instanceApi } from '@dataSystem/api';
import FieldInputOptionsMixin from '../../FieldInputOptionsMixin';

export default {
  mixins: [FieldInputOptionsMixin],
  fieldInputOptions: {
    path: ['structure.options', 'structure.ruleset'],
    keys: ['blueprintId'],
  },
  data() {
    return {
      instanceId: null,
      instanceList: [],
      instanceListLoading: true,
    };
  },
  async mounted() {
    await this.fetchInstances();
  },
  computed: {
    blueprintId() {
      let { blueprintId } = this.$parent.field.structure.ruleset;

      if (this.$parent.field.structure.type === 'array') {
        blueprintId = this.$parent.field.structure.elementStructure.ruleset.blueprintId;
      }
      if (!blueprintId) {
        return null;
      }
      return blueprintId;
    },
  },
  methods: {
    async fetchInstances() {
      this.instanceListLoading = true;

      // De discutat cu Mihai, deoarece acest API nu poate fi apelat fara subtenantId...
      const { instanceList } = await instanceApi.getAll(this.blueprintId, {}, {});
      this.allInstances = instanceList;

      this.instanceListLoading = false;
    },
    toggleDefaultValue(value) {
      this.input.useDefaultValue = value.target.checked;
    },
  },
};
</script>

<style></style>
