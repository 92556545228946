<template>
  <div style="position: relative">
    <template v-if="blueprintId ?? blueprint?._id">
      <a-spin :spinning="isLoading">
        <FormRenderer
          :title="false"
          :description="false"
          :addNew="addNew"
          :showSendSms="object.view.default?.smsFromAddNew"
          :objectId="object._id.toString()"
          :editButtonLabel="editButtonLabel"
          :showEditButton="showEditButton"
          :showDeleteButton="showDeleteButton"
          :deleteButtonLabel="deleteButtonLabel"
          :showFields="showFields"
          :deleteLoading="deleteLoading"
          :submitLoading="isLoading"
          :propsBlueprint="blueprint"
          :blueprintId="blueprintId ?? blueprint._id"
          :propsInstance="localInstance"
          :instanceId="instanceId ?? instance?._id"
          :clearAfterSubmit="clearAfterSubmit"
          :saveMessage="savedMessage"
          @submit="onFormSubmit"
          @delete="onDelete"
          :autoComplete="
            object.view.listFields.reduce((obj, item) => {
              if (item.autoComplete) {
                if (item.autoComplete.isEnabled) {
                  if (!obj[item.autoComplete.triggerField.id]) {
                    obj[item.autoComplete.triggerField.id] = [];
                  }
                  obj[item.autoComplete.triggerField.id].push(item.id);
                }
              }
              return obj;
            }, {})
          "
        >
          <div v-if="userRoles?.length > 1">
            <label style="margin-bottom: 5px; display: block">Owner Role</label>
            <a-select
              :default-value="userRolesWhichCanCreate[0]._id"
              v-model:value="selectedOwnerRoleId"
              style="display: block; width: 200px; margin-bottom: 20px"
            >
              <a-select-option v-for="role in userRoles" :key="role._id" :value="role._id">
                {{ role.name }}
              </a-select-option>
            </a-select>
          </div>
        </FormRenderer>
      </a-spin>
    </template>
  </div>
</template>

<script>
import FormRenderer from '@dataSystem/components/FormRenderer/FormRenderer';
import { isEmpty } from '@/core/utils/string-manipulation';
import { NewTemplatesGetters, NewTemplatesMutations, NewTemplatesActions } from '@templateDataSystem/shared/newTemplateDataSystem.store';
import { message } from 'ant-design-vue';

export default {
  name: 'CreateEditInstance',
  props: [
    'object',
    'addNew',
    'filterConditions',
    'showEditButton',
    'editButtonLabel',
    'deleteButtonLabel',
    'showDeleteButton',
    'showFields',
    'blueprint',
    'blueprintId',
    'instance',
    'instanceId',
    'clearAfterSubmit',
  ],
  emits: ['closePopup'],
  data() {
    return {
      isLoading: false,
      selectedOwnerRoleId: null,
      savedMessage: null,
      deleteLoading: false,
    };
  },
  components: {
    FormRenderer,
  },
  created() {
    if (this.userRolesWhichCanCreate?.length ?? 0 === 1) {
      this.selectedOwnerRoleId = this.userRolesWhichCanCreate[0]._id;
    }
  },
  computed: {
    userRoles: NewTemplatesGetters.getRoles,

    localInstance() {
      const data = {};
      (this.filterConditions ?? []).forEach(filter => {
        (filter?.toReferenceFieldIds ?? []).forEach(item => {
          const field = this.blueprint.fields.find(f => f._id.toString() === item.fieldId.toString());
          if (
            field &&
            (field?.structure?.elementStructure?.type ?? field?.structure?.type) === 'reference' &&
            (filter?.includeInCreateInstance ?? true)
          ) {
            data[item.fieldId] = filter.value;
          }
        });
      });
      const obj = {
        ...(this.instance ?? {}),
        ...data,
      };
      return Object.keys(obj).length ? obj : null;
    },
    userRolesWhichCanCreate() {
      if (Object.keys(this.userRoles).length) {
        return (this.userRoles ?? []).filter(role => {
          const permission = role.permissionByBlueprintId[this.blueprint?._id];
          if (!permission) {
            return false;
          }
          return permission.createAuthority.mode === 'yes';
        });
      }
      return null;
    },
  },
  watch: {
    userRolesWhichCanCreate(value) {
      if (value && value.length > 0) {
        this.selectedOwnerRoleId = value[0]._id;
      }
    },
  },
  methods: {
    async onDelete(instanceData) {
      const el = this;
      this.$confirm({
        title: 'Confirm',
        content: 'Sure you want to delete?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: async () => {
          el.deleteLoading = true;

          const response = await NewTemplatesActions.deleteInstance(el.object._id, el.blueprint._id, instanceData._id);
          if (!response?.error) {
            this.$emit('closePopup');
            NewTemplatesMutations.SET_OPEN_CLOSE_MODAL(`details-${el.object._id}`, false);
          } else {
            message.warning(response.message);
          }
          el.deleteLoading = false;
        },
      });
    },
    async onFormSubmit(instanceData) {
      if (!this.selectedOwnerRoleId && !(await NewTemplatesGetters.getIsPublicTemplate())) {
        message.warning('You must select an Owner Role.');
        return;
      }

      const data = await this.filterData(instanceData);

      this.isLoading = true;
      const response = await NewTemplatesActions.createEditInstance(
        this.object._id,
        this.blueprint._id,
        this.instance?._id,
        data,
        this.selectedOwnerRoleId,
        this.blueprint?.fields ?? []
      );

      this.isLoading = false;

      if (!response?.error) {
        if (!isEmpty(this.object?.view?.default?.saveMessage)) {
          this.savedMessage = this.object?.view?.default?.saveMessage ?? null;
        }
        this.$emit('closePopup');
        NewTemplatesMutations.SET_OPEN_CLOSE_MODAL(`createNew-${this.object._id}`, false);
        NewTemplatesMutations.SET_OPEN_CLOSE_MODAL(`details-${this.object._id}`, false);
      } else {
        message.warning(response.message);
      }

      // if (this.instance?._id ?? false) {
      // TemplatesMutations.SET_OPEN_CLOSE_MODAL(null, false, true)
      // }
    },

    async filterData(data) {
      const newData = {};
      await Promise.all(
        Object.entries(data).map(async ([key, value]) => {
          if (!key.startsWith('_') && key !== 'owner' && key !== 'key') {
            newData[key] = value;
          }
        })
      );
      return newData;
    },
  },
};
</script>

<style lang="scss" scoped>
.InstanceCreator {
  width: 30vw;
  margin: 0 auto;
  @media (max-width: 2000px) {
    width: 40vw;
  }

  @media (max-width: 1500px) {
    width: 70vw;
  }

  @media (max-width: 1000px) {
    width: 90vw;
  }
}
</style>
