<template>
  <a-time-picker
    v-if="!viewingMode"
    style="width: 100%"
    format="H:mm"
    value-format="H:mm"
    v-model:value="inputValue"
    :disabled="readOnly || (field.isReadOnlyAdd && addNew) || (field.isReadOnlyEdit && !addNew)"
    @change="onChange"
    @openChange="onOpenChange"
  />

  <div v-else>
    {{ timeToString }}
  </div>
</template>

<script>
import InputMixin from './InputMixin';

export default {
  mixins: [InputMixin],
  computed: {
    timeToString() {
      if (this.rawValue) {
        const rawValueArr = this.rawValue.split(':');
        return `${rawValueArr[0]}:${rawValueArr[1]}`;
      }
      return '';
    },
  },
  methods: {
    onChange(value) {
      this.inputValue = value;
    },
    onOpenChange(isOpen) {
      if (!isOpen) {
        this.emitBlur();
      }
    },
  },
};
</script>
