<template>
  <div>
    <a-spin v-if="loading" class="text-center d-block p-3" />
    <template v-else>
      <div class="row">
        <div class="col-4">
          Main blueprint
          <a-select
            class="mt-2"
            :value="data.mainCurrencyBlueprintId ?? undefined"
            @change="value => changeSelectValue(value, 'mainCurrencyBlueprintId')"
            allowClear
            show-search
            style="width: 100%"
            placeholder="Select..."
            :filter-option="filterOption"
          >
            <a-select-option v-for="blueprint in allBlueprints" :key="blueprint._id">
              {{ blueprint.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-4">
          Main <strong>Currency</strong> field: * (ex.: EUR, USD)
          <a-tooltip>
            <template #title>De unde imi i-a moneda</template>
            <InfoCircleOutlined />
          </a-tooltip>
          <a-select
            class="mt-2"
            :value="data.mainCurrencyBlueprintFieldId ?? undefined"
            @change="value => changeSelectValue(value, 'mainCurrencyBlueprintFieldId')"
            show-search
            :placeholder="mainBlueprintComp?.name + ' fields'"
            style="width: 100%"
            :filter-option="filterOption"
          >
            <a-select-option v-for="field in mainBlueprintFieldsComp" :key="field._id">
              {{ field.label }} ({{ field.structure.type }})
            </a-select-option>
          </a-select>

          <div v-if="mainCurrencyFieldType === 'reference'">
            <a-select
              class="mt-2"
              :value="data.mainCurrencyReferenceBpFieldId ?? undefined"
              @change="value => changeSelectValue(value, 'mainCurrencyReferenceBpFieldId')"
              show-search
              :placeholder="mainCurrencyFieldParentBp?.name + ' fields'"
              style="width: 100%"
              :filter-option="filterOption"
            >
              <a-select-option v-for="field in mainCurrencyFieldParentBp.fields" :key="field._id">
                {{ field.label }} ({{ field.structure.type }})
              </a-select-option>
            </a-select>
          </div>
        </div>

        <div class="col-4">
          Date field: *
          <a-tooltip>
            <template #title>Date from where it extracts the currency</template>
            <InfoCircleOutlined />
          </a-tooltip>
          <a-select
            class="mt-2"
            :value="data.mainCurrencyBlueprintDateFieldId ?? undefined"
            @change="value => changeSelectValue(value, 'mainCurrencyBlueprintDateFieldId')"
            allowClear
            show-search
            :placeholder="mainBlueprintComp?.name + ' fields'"
            style="width: 100%"
            :filter-option="filterOption"
          >
            <a-select-option v-for="field in mainBlueprintFieldsComp" :key="field._id">
              {{ field.label }} ({{ field.structure.type }})
            </a-select-option>
          </a-select>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-3">
          Select <strong>Exchange</strong> source: *
          <a-tooltip>
            <template #title>Currency list</template>
            <InfoCircleOutlined />
          </a-tooltip>
          <a-select
            class="mt-2"
            :value="data.exchangeBlueprintId ?? undefined"
            @change="value => changeSelectValue(value, 'exchangeBlueprintId')"
            allowClear
            show-search
            style="width: 100%"
            placeholder="Select..."
            :filter-option="filterOption"
          >
            <a-select-option v-for="blueprint in allBlueprints" :key="blueprint._id">
              {{ blueprint.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-3">
          From currency: *
          <a-select
            class="mt-2"
            :value="data.exchangeBlueprintFromCurrencyFieldId ?? undefined"
            @change="value => changeSelectValue(value, 'exchangeBlueprintFromCurrencyFieldId')"
            allowClear
            show-search
            style="width: 100%"
            placeholder="Select..."
            :filter-option="filterOption"
          >
            <a-select-option v-for="field in findExchangeBlueprintFields" :key="field._id">
              {{ field.label }}
            </a-select-option>
          </a-select>
        </div>

        <div class="col-3">
          To currency: *
          <a-select
            class="mt-2"
            :value="data.exchangeBlueprintToCurrencyFieldId ?? undefined"
            @change="value => changeSelectValue(value, 'exchangeBlueprintToCurrencyFieldId')"
            allowClear
            show-search
            style="width: 100%"
            placeholder="Select..."
            :filter-option="filterOption"
          >
            <a-select-option v-for="field in findExchangeBlueprintFields" :key="field._id">
              {{ field.label }}
            </a-select-option>
          </a-select>
        </div>

        <div class="col-3">
          Currency value: *
          <a-select
            class="mt-2"
            :value="data.exchangeBlueprintCurrencyValueFieldId ?? undefined"
            @change="value => changeSelectValue(value, 'exchangeBlueprintCurrencyValueFieldId')"
            allowClear
            show-search
            style="width: 100%"
            placeholder="Select..."
            :filter-option="filterOption"
          >
            <a-select-option v-for="field in findExchangeBlueprintFields" :key="field._id">
              {{ field.label }}
            </a-select-option>
          </a-select>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-3">
          Where to change the value: *
          <a-select
            class="mt-2"
            :value="data.changeCurrencyBlueprintId ?? undefined"
            @change="value => changeSelectValue(value, 'changeCurrencyBlueprintId')"
            allowClear
            show-search
            style="width: 100%"
            placeholder="Select..."
            :filter-option="filterOption"
          >
            <a-select-option v-for="blueprint in allBlueprints" :key="blueprint._id">
              {{ blueprint.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-3">
          <template v-if="data.changeCurrencyBlueprintId">
            Convert field from value: *
            <a-select
              class="mt-2"
              :value="data.changeFromCurrencyBlueprintFieldId ?? undefined"
              @change="value => changeSelectValue(value, 'changeFromCurrencyBlueprintFieldId')"
              allowClear
              show-search
              placeholder="Select field"
              style="width: 100%"
              :filter-option="filterOption"
            >
              <a-select-option v-for="field in findChangeCurrencyBlueprintFields" :key="field._id">
                {{ field.label }} ({{ field.structure.type }})
              </a-select-option>
            </a-select>
          </template>
        </div>
        <div class="col-3">
          <template v-if="data.changeCurrencyBlueprintId">
            Main currency: *
            <a-select
              class="mt-2"
              :value="data.changeMainCurrencyBlueprintFieldId ?? undefined"
              @change="value => changeSelectValue(value, 'changeMainCurrencyBlueprintFieldId')"
              allowClear
              show-search
              placeholder="Select field"
              style="width: 100%"
              :filter-option="filterOption"
            >
              <a-select-option v-for="field in findChangeCurrencyBlueprintFields" :key="field._id">
                {{ field.label }} ({{ field.structure.type }})
              </a-select-option>
            </a-select>
          </template>
        </div>
        <div class="col-3">
          <template v-if="data.changeFromCurrencyBlueprintFieldId">
            Update field to value: *
            <a-select
              class="mt-2"
              :value="data.changeToCurrencyBlueprintFieldId ?? undefined"
              @change="value => changeSelectValue(value, 'changeToCurrencyBlueprintFieldId')"
              allowClear
              show-search
              placeholder="Select field"
              style="width: 100%"
              :filter-option="filterOption"
            >
              <a-select-option v-for="field in findChangeCurrencyBlueprintFields" :key="field._id">
                {{ field.label }} ({{ field.structure.type }})
              </a-select-option>
            </a-select>
          </template>
        </div>
        <div class="col-3">
          <template v-if="data.changeCurrencyBlueprintId">
            Update currency name:
            <a-select
              class="mt-2"
              :value="data.changeToCurrencyNameBlueprintFieldId ?? undefined"
              @change="value => changeSelectValue(value, 'changeToCurrencyNameBlueprintFieldId')"
              allowClear
              show-search
              placeholder="Select field"
              style="width: 100%"
              :filter-option="filterOption"
            >
              <a-select-option v-for="field in findChangeCurrencyBlueprintFields" :key="field._id">
                {{ field.label }} ({{ field.structure.type }})
              </a-select-option>
            </a-select>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { InfoCircleOutlined } from '@ant-design/icons-vue';
import { blueprintApi } from '@dataSystem/api';
import { WorkflowActions } from '@workflow/shared/workflow.store';

export default {
  name: 'workflowBuilderExchangeIndex',
  props: ['workflows', 'selectedWorkflow', 'event', 'eventList'],
  components: {
    InfoCircleOutlined,
  },
  async created() {
    this.loading = true;
    await this.fetchBlueprint();
    this.loading = false;

    this.data = {
      ...this.event.exchange.data,
    };

    if (!this.data.mainCurrencyBlueprintId) {
      this.data.mainCurrencyBlueprintId = this.mainBlueprint._id;
    }
  },
  data() {
    return {
      loading: false,
      allBlueprints: [],

      mainBlueprint: null,
      mainBlueprintFields: [],

      mainCurrencyBlueprintFields: [],
      changeCurrencyBlueprintFields: [],

      //
      data: {
        mainCurrencyBlueprintId: undefined,
        mainCurrencyBlueprintFieldId: undefined,
        mainCurrencyReferenceBpFieldId: undefined,

        exchangeBlueprintId: undefined,

        changeCurrencyBlueprintId: undefined,
        changeFromCurrencyBlueprintFieldId: undefined,
        changeToCurrencyBlueprintFieldId: undefined,

        changeToCurrencyNameBlueprintFieldId: undefined,
      },

      localEvent: { ...this.event },
    };
  },
  watch: {
    async localEvent(data) {
      await WorkflowActions.editOneEvent(this.selectedWorkflow._id, this.event._id, { ...data });
    },
  },
  computed: {
    mainBlueprintComp() {
      if (this.data.mainCurrencyBlueprintId !== this.mainBlueprint?._id) {
        return this.allBlueprints.find(item => item._id === this.data.mainCurrencyBlueprintId);
      }
      return this.mainBlueprint;
    },
    mainBlueprintFieldsComp() {
      if (this.data.mainCurrencyBlueprintId !== this.mainBlueprint?._id) {
        return this.allBlueprints.find(item => item._id === this.data.mainCurrencyBlueprintId)?.fields ?? [];
      }
      return this.mainBlueprintFields;
    },

    selectedBlueprintId() {
      return this.selectedWorkflow.blueprint;
    },

    findChangeCurrencyBlueprintFields() {
      return this.allBlueprints.find(item => item._id === this.data.changeCurrencyBlueprintId)?.fields ?? [];
    },

    findExchangeBlueprintFields() {
      return this.allBlueprints.find(item => item._id === this.data.exchangeBlueprintId)?.fields ?? [];
    },

    mainCurrencyFieldType() {
      const selectedFieldId = this.data?.mainCurrencyBlueprintFieldId;
      const selectedField = this.mainBlueprintFieldsComp.find(f => f._id === selectedFieldId);

      return selectedField?.structure?.type;
    },
    mainCurrencyFieldParentBp() {
      if (this.mainCurrencyFieldType === 'reference') {
        const referenceField = this.mainBlueprintFieldsComp.find(f => f._id === this.data.mainCurrencyBlueprintFieldId);
        if (referenceField) {
          return this.allBlueprints.find(bp => bp._id === referenceField.structure.ruleset.blueprintId);
        }
      }
      return null;
    },
  },
  methods: {
    changeSelectValue(value, keyName) {
      this.localEvent.exchange.data[keyName] = value;
      this.data[keyName] = value;
    },
    async fetchBlueprint() {
      if (!this.selectedBlueprintId) {
        return;
      }
      const { blueprint, fieldsById } = await blueprintApi.getOne(this.selectedBlueprintId);

      await this.fetchAllBlueprint();

      this.mainBlueprint = blueprint;
      this.mainBlueprintFields = Object.values(fieldsById);
    },
    async fetchAllBlueprint() {
      this.allBlueprints = await blueprintApi.getAllWithFields();
    },
    filterOption(input, option) {
      return option.children()[0].children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0;
    },
  },
};
</script>
