import Antd from 'ant-design-vue';
import LogRocket from 'logrocket';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import { __lang, changeLanguage, loadLanguage } from './i18n';

import 'ant-design-vue/dist/reset.css';
import './core/style/bootstrap.min.css';
import './core/style/custom.css';

import './axios.setup';

import LoadingSpinner from './core/components/LoadingSpinner.vue';
import HelpIconPopover from './core/components/HelpIconPopover.vue';

import Clarity from './plugins/clarity';

loadLanguage().then();

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const app = createApp({
  extends: App,
  created() {
    document.title = 'aPlay';
  },
});
if (process.env.NODE_ENV === 'production') {
  app.use(Clarity);
}

app.config.productionTip = false;

app.use(Antd);

app.config.globalProperties.__lang = __lang;
app.config.globalProperties.__changeLanguage = changeLanguage;

app.component('loading-spinner', LoadingSpinner);
app.component('help-icon-popover', HelpIconPopover);

if (process.env.VUE_APP_LOGROCKET_KEY) {
  LogRocket.init(process.env.VUE_APP_LOGROCKET_KEY, {
    dom: {
      inputSanitizer: true,
      textSanitizer: true,
    },
  });
  console.log('LogRocket initialized!');
}
// APP VERSION
app.config.globalProperties.__appVersion = '25.0';
app.use(router);

router.isReady().then(async () => {
  app.mount('#app');
});
