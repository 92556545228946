<template>
  <div>
    <a-table
      bordered
      :locale="{ emptyText: '-' }"
      size="middle"
      :columns="dataColumns"
      :data-source="dataList"
      :class="!this.listActionMode ? 'pointer' : ''"
      :pagination="false"
      :loading="instanceListLoading"
      :row-selection="showCheckboxesColumn"
      :customRow="
        record => {
          return {
            onClick: () => {
              if (!this.listActionMode) {
                rowClick(record);
              }
            },
          };
        }
      "
      @change="fetchInstances"
    >
      <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
        <div style="width: 200px; padding: 8px; display: block">
          <template v-if="blueprint.fields.find(item => item._id === column.key)?.structure?.type === 'date' || column.key === '_created_at'">
            <a-date-picker
              :readOnly="true"
              :placeholder="column.dateRange ? 'From' : 'Date'"
              :format="blueprint.fields.find(item => item._id === column.key)?.structure?.options?.format ?? 'DD-MM-YYYY'"
              :value="selectedKeys[0] ? dayjs(new Date(selectedKeys[0])) : undefined"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="value => selectedKeysHandlerDate(setSelectedKeys, value, selectedKeys, 0, column.dateRange)"
              @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            />
            <a-date-picker
              v-if="column.dateRange"
              :readOnly="true"
              placeholder="To"
              :format="blueprint.fields.find(item => item._id === column.key)?.structure?.options?.format ?? 'DD-MM-YYYY'"
              :value="selectedKeys[1] ? dayjs(new Date(selectedKeys[1])) : undefined"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="value => selectedKeysHandlerDate(setSelectedKeys, value, selectedKeys, 1, column.dateRange)"
              @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex, column.dateRange)"
            />
          </template>
          <template v-else>
            <a-input
              allowClear
              ref="searchInput"
              :placeholder="`Cauta in '${column.title}'`"
              :value="selectedKeys[0]"
              style="margin-bottom: 8px"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
            />
          </template>

          <a-button type="primary" size="small" style="width: 88px; margin-right: 8px" @click="handleSearch(selectedKeys, confirm, column.dataIndex)">
            <template #icon><SearchOutlined /></template>
            Search
          </a-button>
          <a-button size="small" style="width: 88px; position: absolute" @click="handleReset(clearFilters, confirm)"> Reset </a-button>
        </div>
      </template>
      <template #customFilterIcon="{ filtered, column }">
        <FilterOutlined v-if="column?.filters?.length" :style="{ color: filtered ? '#108ee9' : undefined }" />
        <search-outlined v-else :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>

      <template #bodyCell="{ column, record, index }">
        <template v-if="Array.isArray(record[column.key])">
          {{ record[column.key].join(', ') }}
        </template>
        <template v-if="column.type === 'boolean'">
          <a-checkbox :checked="record[column.key]" class="custom-uncheckable-checkbox"></a-checkbox>
        </template>

        <template v-if="column.key === 'action'">
          <div v-if="!record._global || (record._global && user.isSuperAdmin)">
            <div v-if="fixedFirstRow && index === 0">
              {{ keyValueFromString(column, record) }}
            </div>
            <div style="text-align: center" v-else>
              <a-button
                v-if="record?._ownerPermissions?.canRead"
                @click="rowClick(record, index, column, 'view')"
                size="small"
                class="mr-2"
                type="primary"
                ><EyeOutlined />
              </a-button>
              <a-button
                v-if="record?._ownerPermissions?.canEdit"
                @click="rowClick(record, index, column, 'edit')"
                size="small"
                class="mr-2"
                type="primary"
                ><EditOutlined />
              </a-button>
              <a-button
                :loading="deleteLoading === record._id"
                @click="onDelete(record)"
                v-if="
                  (!loadMore || loadMoreView) && listActionModeDelete && record?._ownerPermissions?.canDelete && object.view.default.showDeleteButton
                "
                size="small"
                type="primary"
                danger
                ><DeleteOutlined />
              </a-button>
            </div>
          </div>
        </template>

        <template v-if="column.key === 'linkToReferenceInstance'">
          <div v-if="String(keyValueFromString(column, record)) !== ''">
            <template v-for="(value, i) in String(keyValueFromString(column, record)).split(',')" :key="i">
              <a-tag @click="openLinkToReferenceInstance(column, record, i)" style="white-space: normal; cursor: pointer">{{ value }}</a-tag>
            </template>
          </div>
        </template>

        <template v-if="column.type === 'html'">
          <div>
            <span
              v-if="keyValueFromString(column, record)?.replace(trimHtml, '').replace('&nbsp;', ' ').length > 100"
              v-html="keyValueFromString(column, record)?.replace(trimHtml, '').substring(0, 100) + '...'"
            ></span>
            <span v-else v-html="keyValueFromString(column, record)?.replace(trimHtml, '')"></span>
          </div>
        </template>
      </template>
    </a-table>
  </div>
</template>

<script>
import { keyValueFromString } from '@/core/utils/array-manipulation';
import moment from 'moment/moment';
import dayjs from 'dayjs';
import 'moment/locale/ro';
import { NewTemplatesActions, NewTemplatesGetters, NewTemplatesMutations } from '@templateDataSystem/shared/newTemplateDataSystem.store';
import _ from 'lodash';
import { instanceApi } from '@dataSystem/api';
import { FilterConditions } from '@templateDataSystem/shared/FilterConditions';
import { DeleteOutlined, EditOutlined, EyeOutlined, SearchOutlined, FilterOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';

export default {
  components: {
    SearchOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    FilterOutlined,
  },
  name: 'ListTable',
  props: [
    'object',
    'blueprint',
    'dataColumns',
    'dataList',
    'userRolesWhichCanCreate',
    'userRolesWhichCanDelete',
    'loadMore',
    'loadMoreView',
    'rowsSelected',
    'listActionMode',
    'listActionModeDelete',
    'total',
    'listFields',
    'fixedFirstRow',
    'activateCheckBox',
    'user',
  ],
  emits: [
    'pushRowsSelected',
    'clearSelections',
    'removeRowsSelected',
    'tableFetchInstances',
    'tableRowClick',
    'clearSlections',
    'updateRowsSelected',
    'tableOpenLinkToReferenceInstance',
  ],
  computed: {
    dayjs() {
      return dayjs;
    },
    moment() {
      return moment;
    },
    selectionKeys() {
      return this.dataList
        .filter(item => {
          return this.rowsSelected.findIndex(row => row._id === item._id) > -1;
        })
        .map(a => a.key);
    },
    instanceListLoading() {
      if (this.showCheckboxesColumn) {
        this.initRowSelection();
        if (this.selectionLoading) {
          return true;
        }
      }
      return NewTemplatesGetters.getInstanceListLoading(this.object._id);
    },
    showCheckboxesColumn() {
      if (this.activateCheckBox) {
        return this.rowSelection;
      }
      return null;
    },
  },

  data() {
    return {
      showCheckBoxes: null,
      selectionLoading: false,
      deleteLoading: null,
      trimHtml: /(<([^>]+)>)/gi,
      removed_id: [],
      rowSelection: {
        selectedRowKeys: [],
        hideDefaultSelections: true,
        columnWidth: 65,
        fixed: true,
        onChange: (selectedRowKeys, selectedRows) => {
          if (this.rowSelection.selectedRowKeys.length <= selectedRowKeys.length) {
            const row = selectedRows.find(item => item.key === selectedRowKeys[selectedRowKeys.length - 1]);
            if (!this.rowsSelected.find(item => item.key === selectedRowKeys[selectedRowKeys.length - 1])) {
              this.$emit('pushRowsSelected', row);
            }
          } else {
            const removed = this.dataList.filter(({ _id: id1 }) => !selectedRows.some(({ _id: id2 }) => id2 === id1));
            removed.forEach(item => {
              const index = this.rowsSelected.findIndex(row => row._id === item._id);
              if (index !== -1) {
                this.$emit('removeRowsSelected', index);
              }
            });
          }
        },
        onSelectAll: (selected, selectedRows) => {
          selectedRows.forEach(item => {
            const index = this.rowsSelected.findIndex(row => row._id === item._id);
            if (index === -1) {
              this.$emit('pushRowsSelected', item);
            }
          });
        },
        selections: [
          {
            key: 'all-dataFiltered',
            text: 'All Pages',
            onSelect: () => {
              this.selectAllFiltered();
            },
          },
          {
            key: 'clear-data',
            text: 'Clear All',
            onSelect: () => {
              this.clearSelections();
            },
          },
        ],
      },
    };
  },
  methods: {
    initRowSelection() {
      this.rowSelection.selectedRowKeys = this.selectionKeys;
    },
    fetchInstances(pagination, filters, sorter) {
      this.$emit('tableFetchInstances', pagination, filters, sorter);
    },

    rowClick(record, index, column, actionType) {
      this.$emit('tableRowClick', {
        row: record,
        index,
        column,
        actionType,
      });
    },
    clearSelections() {
      this.$emit('clearSelections');
    },
    async selectAllFiltered() {
      this.selectionLoading = true;
      const query = _.cloneDeep(NewTemplatesGetters.getLastQuery(this.object._id, this.blueprint._id));
      query.limit = null;
      const filterConditions = await FilterConditions(query.filterConditions);
      const queryStripParams = query;
      delete queryStripParams.filterConditions;
      delete queryStripParams.templateId;
      const fieldsToSearch = [];
      this.listFields.forEach(column => {
        if (column.widgetType === 'email') {
          fieldsToSearch.push(column.id);
        }
      });
      queryStripParams.instanceFieldList = fieldsToSearch;
      const response = await instanceApi.getAll(this.blueprint._id, queryStripParams, filterConditions);
      const oldRowSelected = _.cloneDeep(this.rowsSelected);
      this.$emit('updateRowsSelected', response.instanceList);
      const findFilter = Object.values(filterConditions).some(value => value.length > 0);
      if (findFilter) {
        oldRowSelected.forEach(element => {
          if (!this.rowsSelected.find(item => item._id === element._id)) {
            this.$emit('pushRowsSelected', element);
          }
        });
      }
      this.selectionLoading = false;
    },

    openLinkToReferenceInstance(column, record, index) {
      this.$emit('tableOpenLinkToReferenceInstance', column, record, index);
    },

    selectedKeysHandlerDate(setSelectedKeys, value, keys, index, range) {
      if (!range) {
        setSelectedKeys(value ? [value.format('MM-DD-YYYY')] : []);
      } else {
        const newValue = [...(keys ?? [])];
        if (value) {
          newValue.splice(index, 1, value.format('MM-DD-YYYY'));
        } else {
          newValue.splice(index, 1);
        }
        if (!newValue[0]) {
          newValue[0] = 0;
        }
        if (!newValue[1]) {
          newValue[1] = 0;
        }
        setSelectedKeys(newValue);
      }
    },

    async onDelete(instanceData) {
      const el = this;
      this.$confirm({
        title: 'Confirm',
        content: 'Sure you want to delete?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: async () => {
          el.deleteLoading = instanceData._id;
          const response = await NewTemplatesActions.deleteInstance(el.object._id, el.blueprint._id, instanceData._id);
          if (!response?.error) {
            NewTemplatesMutations.SET_OPEN_CLOSE_MODAL(`details-${el.object._id}`, false);
          } else {
            message.warning(response.message);
          }
          el.deleteLoading = null;
        },
      });
    },

    keyValueFromString(column, record) {
      const value = keyValueFromString(column.key, record);
      if (Array.isArray(value)) {
        return value.join(', ');
      }
      return value;
    },
    handleSearch(selectedKeys, confirm) {
      confirm();
    },
    handleReset(clearFilters, confirm) {
      clearFilters();
      confirm();
    },
  },
};
</script>
<style scoped>
/* Make the checkbox unclickable but visually enabled */
.custom-uncheckable-checkbox {
  pointer-events: none; /* Disable interaction */
}
</style>
