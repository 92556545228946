<template>
  <div v-if="!viewingMode">
    <a-select
      :getPopupContainer="trigger => trigger.parentElement"
      :disabled="instanceListLoading || readOnly || (field.isReadOnlyAdd && addNew) || (field.isReadOnlyEdit && !addNew)"
      :loading="instanceListLoading"
      show-search
      @search="handleSearch"
      :placeholder="
        inputPlaceholder && inputPlaceholder !== '' ? inputPlaceholder : field.isDynamic ? 'Enter at least 3 characters to search...' : ''
      "
      option-filter-prop="display-field-value"
      style="width: 100%"
      v-model:value="referenceInputValue"
      :allowClear="!readOnly"
      :filterOption="
        field.isDynamic
          ? () => true
          : (inputValue, option) => {
              return filterSelectOptions(inputValue, option);
            }
      "
      :open="selectOpen"
      @change="handleSelectChange"
      @dropdownVisibleChange="handleDropdownVisibleChange"
    >
      <a-select-option v-for="instance in instanceListUpdated" :key="instance.key" :value="instance.key" :display-field-value="instance.label">
        {{ instance.label }}
      </a-select-option>
    </a-select>
  </div>

  <div v-else>
    <a-select
      :loading="false"
      :placeholder="inputPlaceholder"
      option-filter-prop="display-field-value"
      style="width: 100%"
      v-model:value="viewingModeSelectedInstanceText"
      :readOnly="true"
      :allowClear="false"
      :showSearch="false"
      :autoSize="true"
      :disabled="false"
      :open="false"
    >
    </a-select>
  </div>
</template>

<script>
import { blueprintApi, instanceApi } from '@dataSystem/api';
import { SlugGetters } from '@/slug.store';
import InputMixin from './InputMixin';

export default {
  mixins: [InputMixin],
  emits: ['update:modelValue'],
  data() {
    return {
      referenceInputValue: undefined,
      mountFinish: false,
      blueprint: null,
      allInstances: [],
      instanceListLoading: true,
      latestConditionedSelectedValues: {},
      oldInputValue: null,
      searchTimeout: null,
      searchedText: '',
      viewingModeLoading: true,
      viewingModeSelectedInstance: null,
      selectOpen: false,
    };
  },
  async mounted() {
    if (!this.viewingMode) {
      this.$emit('update:modelValue', this.inputValue);
      if (this.inputValue) {
        this.referenceInputValue = this.inputValue;
      }

      if (SlugGetters.getSubtenantSlug()) {
        await this.fetchBlueprint();
        if (this.field.isDynamic) {
          await this.fetchSelectedInstance();
        } else {
          await this.fetchInstances();
        }
        this.instanceListLoading = false;

        // Set referenceInputValue based on the default value conditions
        if (this.isInputReference() && this.hasFirstValueAsDefault() && this.addNew) {
          const firstInstance = this.instanceListUpdated[0];
          if (firstInstance) {
            this.referenceInputValue = firstInstance.key;
          }
        } else if (this.inputValue) {
          this.referenceInputValue = this.inputValue;
        }
      }
    } else {
      await this.fetchBlueprint();
      await this.fetchViewingModeSelectedInstance();
      this.viewingModeLoading = false;
    }
  },
  computed: {
    viewingModeSelectedInstanceText() {
      if (this.viewingMode && this.allInstances.length) {
        const selectedInstance = this.allInstances.find(i => i._id === this.rawValue);
        if (selectedInstance) {
          return this.selectDisplayFieldsId(selectedInstance);
        }
      }
      return '';
    },
    computedCurrentFieldValues() {
      return { ...this.currentFieldValues };
    },
    instanceListUpdated() {
      return this.allInstances.map(item => {
        return {
          key: item._id,
          label: this.selectDisplayFieldsId(item),
        };
      });
    },
    blueprintId() {
      let { blueprintId } = this.field.structure.ruleset;
      if (this.field.structure.type === 'array') {
        blueprintId = this.field.structure.elementStructure.ruleset.blueprintId;
      }
      if (!blueprintId) {
        return null;
      }
      return blueprintId;
    },
  },
  watch: {
    computedCurrentFieldValues: {
      deep: true,
      handler(val, oldVal) {
        this.updateInstances(val, oldVal);
      },
    },
    inputValue(value) {
      this.referenceInputValue = value;
    },
    referenceInputValue() {
      if (this.referenceInputValue) {
        this.inputValue = this.referenceInputValue;
      } else {
        this.inputValue = null;
      }
    },
  },
  methods: {
    handleSelectChange() {
      this.selectOpen = false;
    },
    handleDropdownVisibleChange(visible) {
      this.selectOpen = visible;
    },
    async setValueAndFetchInstances(instanceId) {
      if (this.field.isDynamic) {
        await this.fetchInstances(null, instanceId);
      } else {
        await this.fetchInstances();
      }
      this.referenceInputValue = instanceId;
    },
    filterSelectOptions(searchStr, options) {
      return (
        options['display-field-value']
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .indexOf(searchStr.toLowerCase()) >= 0
      );
    },
    async handleSearch(searchTerm) {
      if (this.field.isDynamic) {
        this.searchedText = searchTerm;
        clearTimeout(this.searchTimeout);

        if (searchTerm.length >= 3) {
          const searchSettings = {
            searchTerm,
            config: {
              displayFieldsTokens: this.field.displayFieldsTokens,
              displayFieldsId: this.field.displayFieldsId,
            },
          };
          this.searchTimeout = setTimeout(async () => {
            await this.fetchInstances(searchSettings);
            // this.filterInstances();
          }, 750);
        }
      }
    },
    async fetchBlueprint() {
      if (!this.blueprintId) {
        return;
      }
      const { blueprint } = await blueprintApi.getOne(this.blueprintId);
      this.blueprint = blueprint;
    },
    selectDisplayFieldsId(instance) {
      if (this.field.displayFieldsTokens && this.field.displayFieldsTokens !== '' && instance[`_ref_display_val_tokens_${this.field._id}`]) {
        const value = instance[`_ref_display_val_tokens_${this.field._id}`];
        if (Array.isArray(value)) {
          return value.join(', ');
        }
      }
      return instance[this.blueprint.displayFieldId ?? '_id'];
    },
    async fetchSelectedInstance() {
      if (this.inputValue && this.blueprintId) {
        await this.fetchInstances(null, this.inputValue);
      }
    },
    async fetchViewingModeSelectedInstance() {
      if (this.rawValue && this.blueprintId) {
        const params = {};

        params.instanceId = this.rawValue;

        if (this.field.displayFieldsTokens && this.field.displayFieldsTokens !== '') {
          params.displayFieldTokens = this.field._id;
        }

        const { instanceList } = await instanceApi.getAll(this.blueprintId, params, []);
        this.allInstances = instanceList;
      }
    },
    async updateInstances(currentFieldValues) {
      let shouldUpdate = false;
      Object.keys(this.latestConditionedSelectedValues).forEach(key => {
        if (this.latestConditionedSelectedValues[key] !== currentFieldValues[key]) {
          shouldUpdate = true;
        }
      });
      if (shouldUpdate) {
        await this.fetchInstances();
      }
    },
    async fetchInstances(searchTerm = null, instanceId = null) {
      this.instanceListLoading = true;
      if (!this.blueprintId) {
        return;
      }

      const params = {};

      if (this.field.displayFieldsTokens && this.field.displayFieldsTokens !== '') {
        params.displayFieldTokens = this.field._id;
      }

      // fetch instances conditioned by user entered search string
      if (searchTerm) {
        params.filters = {};
        if (this.field.displayFieldsTokens && this.field.displayFieldsTokens === '') {
          params.filters[this.field._id] = [searchTerm.searchTerm];
        } else {
          params.filters[`_ref_display_val_tokens_${this.field._id}`] = [searchTerm.searchTerm];
        }
      }

      // fetch specific instance
      if (instanceId) {
        params.instanceId = instanceId;
      }

      // if field instances are ordered
      // sortFieldId
      // sortOrder
      if (this.field.logic?.sorting?.isEnabled) {
        if (this.field.logic?.sorting?.conditions?.length) {
          if (this.field.logic?.sorting?.conditions[0].fieldId && this.field.logic?.sorting?.conditions[0].order) {
            params.sortFieldId = this.field.logic.sorting.conditions[0].fieldId;
            params.sortOrder = this.field.logic.sorting.conditions[0].order;
          }
        }
      }

      // apply filter conditions
      let shouldGetInstances = true;
      const filterConditions = [];

      if (this.field.logic?.filter && this.field.logic.filter.isEnabled) {
        this.field.logic.filter.conditions.forEach(condition => {
          if (condition.blueprintId && condition.fieldId && condition.fieldType) {
            const toReferenceFieldIds = [
              {
                blueprintId: condition.blueprintId,
                fieldId: condition.fieldId,
                type: condition.fieldType,
              },
            ];

            filterConditions.push({
              operation: 'and',
              comparation: condition.comparator,

              includeInCreateInstance: true,

              toFieldFromOtherBlueprint: false,
              toReferenceFieldIds,

              value: condition.expectedValue,
            });
          }
        });

        this.field.logic.filter.references.forEach(condition => {
          if (condition.blueprintId && condition.fieldId && condition.fieldType) {
            const currentConditionSelectedValue = this.currentFieldValues[condition.fieldId];
            if (!currentConditionSelectedValue) {
              shouldGetInstances = false;
            }

            const toReferenceFieldIds = [
              {
                blueprintId: condition.blueprintId,
                fieldId: condition.connectionFieldId,
                type: condition.fieldType,
              },
            ];

            filterConditions.push({
              operation: 'and',
              comparation: condition.comparator,

              includeInCreateInstance: true,

              toFieldFromOtherBlueprint: false,
              toReferenceFieldIds,

              value: currentConditionSelectedValue,
            });

            this.latestConditionedSelectedValues[condition.fieldId] = currentConditionSelectedValue;
          }
        });
      }

      if ((!this.field.isDynamic && shouldGetInstances) || (this.field.isDynamic && (searchTerm || instanceId) && shouldGetInstances)) {
        const { instanceList } = await instanceApi.getAll(this.blueprintId, params, filterConditions);
        this.allInstances = instanceList;
      } else {
        this.allInstances = [];
        this.referenceInputValue = undefined;
      }

      if (Array.isArray(this.allInstances) && !this.allInstances.find(instance => instance._id === this.inputValue)) {
        this.referenceInputValue = undefined;
      }

      this.instanceListLoading = false;
    },
  },
};
</script>

<style></style>
