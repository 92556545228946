<template>
  <div>
    <a-row type="flex">
      <a-col flex="250px">
        <RoleSelect :roles="roles" @select="onRoleSelect" @create="onRoleCreate" @remove="onRoleRemove" />
      </a-col>
      <a-col flex="auto">
        <div v-if="selectedRole" style="margin: 0 12px">
          <a-tabs v-model:activeKey="activeKey">
            <a-tab-pane key="1" tab="Permissions"></a-tab-pane>
            <a-tab-pane key="2" tab="Services" force-render></a-tab-pane>
          </a-tabs>
          <PermissionByBlueprint
            v-if="activeKey === '1'"
            :blueprintById="blueprintById"
            :permissionByBlueprintId="selectedRole.permissionByBlueprintId"
            @update="onPermissionUpdate"
          />
          <ServicesAccessByBlueprint v-else :servicesAccess="selectedRole.servicesAccess" @update="onServiceAccessUpdate" />
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { blueprintApi } from '@dataSystem/api';
import { RolesGetters, RolesActions } from '@roleManagement/shared/role.application.store';
import RoleSelect from './components/RoleSelect.vue';

import PermissionByBlueprint from './components/PermissionByBlueprint.vue';
import ServicesAccessByBlueprint from './components/ServicesAccessByBlueprint.vue';

export default {
  data() {
    return {
      activeKey: '1',
      roles: [],
      blueprints: [],
      selectedRoleId: null,
    };
  },
  created() {
    this.fetchRoles();
    this.fetchBlueprints();
  },
  components: {
    RoleSelect,
    PermissionByBlueprint,
    ServicesAccessByBlueprint,
  },
  computed: {
    blueprintById() {
      return Object.fromEntries(this.blueprints.map(blueprint => [blueprint._id, blueprint]));
    },
    selectedRole() {
      return this.roles.find(role => role._id === this.selectedRoleId);
    },
  },
  methods: {
    onRoleSelect(selectedRoleId) {
      this.selectedRoleId = selectedRoleId;
    },
    async fetchBlueprints() {
      this.blueprints = await blueprintApi.getAll();
    },
    async fetchRoles() {
      await RolesActions.fetchRoles();
      this.roles = RolesGetters.getRoles();
    },
    async onRoleCreate(newRoleName) {
      await RolesActions.createRole({
        roleInput: {
          name: newRoleName,
        },
      });
      this.roles = RolesGetters.getRoles();
    },
    async onRoleRemove(roleIdToRemove) {
      await RolesActions.deleteRole({ roleId: roleIdToRemove });
      this.roles = RolesGetters.getRoles();
    },
    async onPermissionUpdate({ blueprintId, permissionInput }) {
      await RolesActions.updateRolePermission({
        roleId: this.selectedRole._id,
        blueprintId,
        permissionInput,
      });
      this.roles = RolesGetters.getRoles();
    },
    async onServiceAccessUpdate({ serviceInput }) {
      await RolesActions.updateRoleServiceAccess({
        roleId: this.selectedRole._id,
        serviceInput,
      });
      this.roles = RolesGetters.getRoles();
    },
  },
};
</script>

<style></style>
