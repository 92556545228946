<template>
  Days:
  <div class="row">
    <div class="col-6">
      <div class="cell modify-filed">
        <a-radio-group button-style="solid" v-model:value="condition.manuallyDateDaysCondition">
          <a-radio-button value="static-value">Value</a-radio-button>
          <a-radio-button value="from-field">Field</a-radio-button>
        </a-radio-group>
      </div>
    </div>
    <div class="col-6">
      <a-input-number style="width: 100%" v-model:value="condition.manuallyDateDays" v-if="condition.manuallyDateDaysCondition === 'static-value'" />
      <ReferenceFieldManger
        v-if="blueprints && mainSelectedBlueprint && condition.manuallyDateDaysCondition === 'from-field'"
        style="width: 100%; margin-top: -15px"
        v-model="condition.manuallyDateDaysFromField"
        :field="returnField(condition.manuallyDateDaysFromField)"
        :blueprints="blueprints"
        :selectedBlueprint="mainSelectedBlueprint"
        :toFieldFromOtherBlueprint="false"
        :justReferenceFields="false"
        :justReferenceAndIds="false"
        :autoFieldConditions="true"
        :mainBlueprint="mainSelectedBlueprint"
        :mainBlueprintId="mainSelectedBlueprint._id.toString()"
        :cardView="false"
        :otherBlueprint="false"
        :forceShowId="false"
        :noCondition="true"
      />
    </div>
  </div>
  Mounts:
  <div class="row">
    <div class="col-6">
      <div class="cell modify-filed">
        <a-radio-group button-style="solid" v-model:value="condition.manuallyDateMountsCondition">
          <a-radio-button value="static-value">Value</a-radio-button>
          <a-radio-button value="from-field">Field</a-radio-button>
        </a-radio-group>
      </div>
    </div>

    <div class="col-6">
      <a-input-number
        style="width: 100%"
        v-model:value="condition.manuallyDateMounts"
        v-if="condition.manuallyDateMountsCondition === 'static-value'"
      />
      <ReferenceFieldManger
        v-if="blueprints && mainSelectedBlueprint && condition.manuallyDateMountsCondition === 'from-field'"
        style="width: 100%; margin-top: -15px"
        v-model="condition.manuallyDateMountsFromField"
        :field="returnField(condition.manuallyDateMountsFromField)"
        :blueprints="blueprints"
        :selectedBlueprint="mainSelectedBlueprint"
        :toFieldFromOtherBlueprint="false"
        :justReferenceFields="false"
        :justReferenceAndIds="false"
        :autoFieldConditions="true"
        :mainBlueprint="mainSelectedBlueprint"
        :mainBlueprintId="mainSelectedBlueprint._id.toString()"
        :cardView="false"
        :otherBlueprint="false"
        :forceShowId="false"
        :noCondition="true"
      />
    </div>
  </div>
</template>
<script>
import ReferenceFieldManger from '@/apps/templateManagement/views/Builder/components/referenceFieldsComponents/ReferenceFieldManger.vue';

export default {
  name: 'AddToDate',
  components: {
    ReferenceFieldManger,
  },
  emits: ['addNumbersToDate'],
  props: [
    'manuallyDateDaysCondition',
    'manuallyDateDays',
    'manuallyDateDaysFromField',
    'manuallyDateMountsCondition',
    'manuallyDateMounts',
    'manuallyDateMountsFromField',
    'blueprints',
    'mainSelectedBlueprint',
  ],
  data() {
    return {
      condition: {
        manuallyDateDaysCondition: this.manuallyDateDaysCondition ?? 'static-value',
        manuallyDateDays: this.manuallyDateDays ?? 0,
        manuallyDateDaysFromField: this.manuallyDateDaysFromField ?? undefined,
        manuallyDateMountsCondition: this.manuallyDateMountsCondition ?? 'static-value',
        manuallyDateMounts: this.manuallyDateMounts ?? 0,
        manuallyDateMountsFromField: this.manuallyDateMountsFromField ?? undefined,
      },
    };
  },
  watch: {
    condition: {
      deep: true,
      handler() {
        this.$emit('addNumbersToDate', this.condition);
      },
    },
  },
  methods: {
    returnField(field) {
      return field;
    },
  },
};
</script>
<style>
.divAsFieldset {
  background-color: #fafafa;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 5px;
}
.labelFieldset {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-left: none; /* Remove the full-height borders */
  border-right: none;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 0;
  background-color: #fafafa;
  width: 60px;
  position: absolute;
  left: 50%;
  top: -11px;
  transform: translateX(-50%);
  text-align: center;
  border-radius: 3px;
  z-index: 1; /* Ensure the label stays on top */
}

.labelFieldset::before,
.labelFieldset::after {
  content: '';
  position: absolute;
  width: 1px; /* The thickness of the borders */
  height: 50%; /* Make the border half the height */
  background-color: rgba(0, 0, 0, 0.2); /* Match the color of the border */
}

.labelFieldset::before {
  left: 0px; /* Adjust to match the position of the original left border */
  top: 0; /* Start from the top */
}

.labelFieldset::after {
  right: 0px; /* Adjust to match the position of the original right border */
  top: 0;
}
</style>
