<template>
  <div>
    <h2>Blueprint destination</h2>
    {{ fieldMapperId }}
    <div class="saas_card mb-10" v-for="destination in destinations.fieldMapper" :key="destination._id">
      <div class="row-contents">
        <div class="group-text-container">
          <div class="group-text">
            <span class="title" v-if="getBlueprintById(destination.blueprintId)">
              <strong>
                {{ getBlueprintById(destination.blueprintId).name }}
              </strong>

              <span style="padding-left: 10px">fields</span>

              <a-button class="ml-3" @click="openMapperOptionsModal(destination)">
                <template #icon><SettingOutlined /></template>
                Data insertion options
              </a-button>
            </span>
            <span class="muted-text" v-if="destination.blueprintParentInstanceId">
              <span v-if="instanceName[destination.blueprintParentInstanceId]">
                ON
                <strong>
                  {{ instanceName[destination.blueprintParentInstanceId] }}
                </strong>
              </span>
            </span>
          </div>
        </div>
        <div class="metadata">
          <div class="controls">
            <a-popconfirm placement="topRight" ok-text="Yes" cancel-text="No" @confirm="removeMapper(destination._id)">
              <template #title>
                <p>Are you sure you want to delete?</p>
              </template>
              <a-button :loading="removeBindMapperPreloader"><DeleteOutlined /></a-button>
            </a-popconfirm>
          </div>
        </div>
      </div>

      <div class="details-container">
        <div class="content">
          <div v-if="getBlueprintFields(destination.blueprintId).length">
            <template v-for="field in getBlueprintFields(destination.blueprintId)" :key="field._id">
              <drop
                class="saas_card mb-10"
                :accepts-data="() => getMappedFiled(destination.blueprintId, field._id).length < 1"
                @drop="onDropField(destination.blueprintId, destination.blueprintParentInstanceId, field._id, $event)"
              >
                <div class="row-contents">
                  <div class="dataRow">
                    <div class="cell">
                      <div class="group-text-container group-text center">
                        <strong>
                          {{ field.label }}
                          <a-tag>Field ID: {{ field.successionIndex }}</a-tag>
                        </strong>
                      </div>
                    </div>
                    <div class="cell l-line" v-if="getDataflowField(destination.blueprintId, field._id)">
                      <SwapOutlined class="middle-icon" />
                      <div class="group-text-container saas_hoverable group-text" style="padding-left: 17px">
                        <strong>
                          {{ getDataflowField(destination.blueprintId, field._id).label }}
                        </strong>
                        <a-popconfirm
                          placement="topRight"
                          ok-text="Yes"
                          cancel-text="No"
                          @confirm="removeBindFiled(destination.blueprintId, field._id)"
                        >
                          <template #title>
                            <p>Are you sure you want to delete?</p>
                          </template>
                          <a-button :loading="removeBindPreloader" size="small" style="position: absolute; right: 6px"><DeleteOutlined /></a-button>
                        </a-popconfirm>
                      </div>
                    </div>
                  </div>
                </div>
              </drop>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-10" v-if="addAnother">
      <div>
        <label style="min-width: 125px; margin-right: 15px">Select a Blueprint</label>
        <BlueprintSelect v-model="selectedBlueprintId" placeholder="Select a blueprint..." style="width: 500px" />
      </div>

      <div>
        <label style="min-width: 125px; margin-right: 15px"
          >Select Owner (TODO: in caz ca vrem sa atribuim instantele unui user, in cazul asta vor fi inserate doar pt subtenantul din care acel user
          face parte)</label
        >
        <a-select
          v-model:value="selectedUserId"
          placeholder="Select the user to which these instances will be attributed"
          style="min-width: 500px"
          show-search
        ></a-select>
      </div>

      <div>
        <label style="min-width: 125px; margin-right: 15px">Select Role (TODO: in caz ca vrem sa atribuim un rol instantelor adaugate)</label>
        <a-select
          v-model:value="selectedOwnerRoleId"
          placeholder="Select the role which these instances will have"
          style="min-width: 500px"
          show-search
        ></a-select>
      </div>

      <a-button type="primary" v-if="selectedBlueprintId" @click="addSelectedLocation" style="margin-left: 20px"> <PlusOutlined /> ADD </a-button>
    </div>
    <div v-else style="text-align: right">
      <a-button @click="addAnother = true"><PlusOutlined /> Add blueprint</a-button>
    </div>
  </div>

  <a-modal
    v-model:open="modalInsertOptionsOpen"
    title="Mapper data insertion options"
    @ok="modalInsertOptionsOpen = false"
    :cancelButtonProps="{ style: { display: 'none' } }"
    :afterClose="saveMapperOptions"
  >
    <div v-if="editedMapper">
      <a-tabs v-model:activeKey="insertOptionsActiveKey">
        <a-tab-pane key="1" tab="Creation">
          <div>
            <a-radio-group v-model:value="editedMapper.insertingOptions.insertingType">
              <a-radio :value="'new'">Always insert as new</a-radio>
              <a-radio :value="'update'">
                Update if exists, insert as new if not
                <help-icon-popover title="Update instances" content="Select one or more fields to determine the instance uniqueness (example: id)."
              /></a-radio>
            </a-radio-group>
          </div>

          <div v-if="editedMapper.insertingOptions.insertingType === 'update'">
            <a-select
              v-model:value="editedMapper.insertingOptions.uniqueFields"
              class="mt-3"
              mode="multiple"
              style="width: 100%"
              placeholder="Select unique field(s)"
              :options="destinationBpFieldOptions"
            ></a-select>
          </div>
        </a-tab-pane>

        <a-tab-pane key="2" tab="Ownership / type">
          <a-radio-group v-model:value="editedMapper.insertingOptions.instanceType">
            <a-radio :value="'default'">
              Default
              <help-icon-popover
                title="Default"
                content="If the associated Dataflow is Manual, the instances will be created only for the Subtenant from which the Dataflow action was initiated. If it is Auto, the instances will be created as duplicates for every Subtenant."
              />
            </a-radio>

            <a-radio :value="'global'">
              Global
              <help-icon-popover
                title="Global instances"
                content="Created instances will be marked as Global and will be available for all Subtenants and all Applications that use this Blueprint."
              />
            </a-radio>

            <a-radio :value="'selective'" disabled="disabled">
              Selective (NOT AVAILABLE)
              <help-icon-popover
                title="Selective Subtenants"
                content="If the selective mode is chosen the instances will be created only for the selected subtenants."
              />
            </a-radio>
          </a-radio-group>
        </a-tab-pane>
      </a-tabs>
    </div>
  </a-modal>
</template>

<script>
import { Drop } from 'vue-easy-dnd';

import { blueprintApi } from '@dataSystem/api';
import { BlueprintSelect } from '@dataSystem/components/BlueprintSelect';

import { FieldMapperActions } from '@fieldMapper/shared/fieldMapper.store';
import { DeleteOutlined, PlusOutlined, SwapOutlined, SettingOutlined } from '@ant-design/icons-vue';

export default {
  components: {
    Drop,
    BlueprintSelect,
    SwapOutlined,
    DeleteOutlined,
    PlusOutlined,
    SettingOutlined,
  },
  props: ['fieldMapperId', 'destinationType', 'outputProviderFields', 'destinations', 'specialOutputProviderFields'],
  data() {
    return {
      loading: false,
      addAnother: false,
      removeBindPreloader: false,
      removeBindMapperPreloader: false,

      instanceName: [],

      blueprintById: {},

      selectedBlueprintId: null,
      blueprintParentInstances: null,
      blueprintParentInstanceId: null,
      loadingBlueprintParentInstances: false,

      selectedUserId: undefined,
      selectedOwnerRoleId: undefined,

      modalInsertOptionsOpen: false,
      editedMapper: null,
      editedMapperBlueprintId: null,
      insertOptionsActiveKey: '1',
    };
  },
  created() {
    this.initBlueprintById();
  },
  computed: {
    destinationBpFieldOptions() {
      if (this.editedMapper && Array.isArray(this.blueprintById[this.editedMapper.blueprintId].fields)) {
        return this.blueprintById[this.editedMapper.blueprintId].fields.map(f => {
          return {
            value: f._id,
            label: f.label,
          };
        });
      }
      return [];
    },
  },
  methods: {
    async initBlueprintById() {
      const promises = this.destinations.fieldMapper.map(async destination => {
        const { blueprint, fieldsById } = await blueprintApi.getOne(destination.blueprintId);

        this.blueprintById[blueprint._id] = {
          ...blueprint,
          fields: Object.values(fieldsById),
        };
      });

      await Promise.all(promises);
    },
    getBlueprintById(blueprintId) {
      return this.blueprintById[blueprintId];
    },
    getBlueprintFields(blueprintId) {
      return this.blueprintById[blueprintId] ? this.blueprintById[blueprintId].fields : [];
    },
    getMappedFiled(blueprintId, blueprintFieldId) {
      const findDestination = this.destinations.fieldMapper.find(d => d.blueprintId === blueprintId);
      if (findDestination) {
        const normalField = findDestination.boundFields.filter(m => m.blueprintFieldId === blueprintFieldId);
        const specialField = findDestination.boundSpecialFields.filter(m => m.blueprintFieldId === blueprintFieldId);

        return normalField.length ? normalField : specialField;
      }
      return [];
    },
    getDataflowField(blueprintId, blueprintFieldId) {
      if (blueprintId && blueprintFieldId) {
        const mapedField = this.getMappedFiled(blueprintId, blueprintFieldId);
        if (mapedField && mapedField.length) {
          if (['_instanceId', '_blueprintId', '_userId', '_subtenantId', '_fileDownload'].includes(mapedField[0].dataProviderFieldId)) {
            return this.specialOutputProviderFields.find(d => d.originalId === mapedField[0].dataProviderFieldId);
          }

          return this.outputProviderFields.find(d => d.originalId === mapedField[0].dataProviderFieldId);
        }
      }
      return undefined;
    },

    async saveMapperOptions() {
      await FieldMapperActions.updateOneMapper(this.fieldMapperId, this.editedMapper._id, {
        insertingOptions: {
          insertingType: this.editedMapper?.insertingOptions?.insertingType,
          uniqueFields: this.editedMapper?.insertingOptions?.uniqueFields,
          instanceType: this.editedMapper?.insertingOptions?.instanceType,
        },
      });
      this.editedMapper = null;
    },
    openMapperOptionsModal(mapper) {
      this.editedMapper = mapper;
      this.modalInsertOptionsOpen = true;
    },
    async removeMapper(mapperId) {
      this.removeBindMapperPreloader = true;
      await FieldMapperActions.deleteOneMapper(this.fieldMapperId, mapperId);
      this.removeBindMapperPreloader = false;
    },
    async removeBindFiled(blueprintId, blueprintFieldId) {
      if (blueprintId && blueprintFieldId) {
        this.removeBindPreloader = true;
        const mappedFiled = this.getMappedFiled(blueprintId, blueprintFieldId);
        if (mappedFiled.length) {
          await FieldMapperActions.deleteOneBind(this.fieldMapperId, mappedFiled[0]._id, mappedFiled[0].dataProviderFieldId);
        }
        this.removeBindPreloader = false;
      }
    },

    async onDropField(blueprintId, blueprintParentInstanceId, blueprintFieldId, e) {
      if (e.data.originalId === '_fileDownload') {
        const bpFields = this.getBlueprintFields(blueprintId);
        const field = bpFields.find(f => f._id === blueprintFieldId);
        if (field.structure.type !== 'file') {
          this.$message.warning('Selected field type needs to be "File"!');
          return;
        }
      }
      await FieldMapperActions.createOneBind(this.fieldMapperId, {
        blueprintId,
        blueprintParentInstanceId,
        dataProviderFieldId: e.data.originalId,
        blueprintFieldId,
      });
    },

    async addSelectedLocation() {
      if (this.selectedBlueprintId) {
        await FieldMapperActions.createOneMapper(this.fieldMapperId, {
          type: this.destinationType,
          blueprintId: this.selectedBlueprintId,
          userId: this.selectedUserId,
          ownerRoleId: this.selectedOwnerRoleId,
        });

        await this.initBlueprintById();

        this.selectedBlueprintId = null;
        this.addAnother = false;
      }
    },
  },
};
</script>
<style scoped>
.dataRow {
  position: relative;
  display: table;
  width: 100%;
}
.dataRow .cell {
  width: 50%;
  display: table-cell;
}
.dataRow .cell.pl-15 {
  padding-left: 15px;
}
.dataRow .cell.l-line {
  border-left: 1px solid #ddd;
}

.middle-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 2px 3px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.saas_hoverable:hover {
  background-color: rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
  cursor: pointer;
}
.hoverable_drag:hover {
  background-color: rgba(0, 0, 0, 0.02);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
}
.hoverable_drag:active {
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
}

.drag-in {
  cursor: move !important;
}

.drop-allowed {
  background-color: rgba(0, 255, 0, 0.2);
}

.drop-forbidden {
  background-color: rgba(255, 0, 0, 0.2);
}

.drop-in {
  border: 1px dashed rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

.list-enter,
.list-leave-to {
  opacity: 0;
}

.list-leave-active {
  position: absolute;
}
</style>
