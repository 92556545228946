<template>
  <component
    v-if="!viewingMode"
    :is="inputComponent"
    v-model:value="inputValue"
    :readOnly="readOnly"
    :required="field.isRequired"
    :maxLength="structureOptions.maxLength"
    :placeholder="inputPlaceholder"
    :size="inputSize"
    :auto-focus="autoFocus"
    :autoSize="true"
    :disabled="(field.isReadOnlyAdd && addNew) || (field.isReadOnlyEdit && !addNew)"
    @blur="e => $emit('blur', e)"
    @pressEnter="e => $emit('pressEnter', e)"
    :loading="waitForValidity"
  />

  <div v-else>
    <component
      :is="inputComponent"
      v-model:value="rawValue"
      :required="field.isRequired"
      :maxLength="structureOptions.maxLength"
      :placeholder="inputPlaceholder"
      :size="inputSize"
      :readOnly="true"
      :autoSize="true"
      :disabled="false"
    />
  </div>
</template>

<script>
import InputMixin from './InputMixin';

export default {
  mixins: [InputMixin],
  emits: ['pressEnter', 'blur'],

  computed: {
    inputComponent() {
      if (this.inputRuleset && this.inputRuleset.isMultiLine) {
        return 'a-textarea';
      }
      if (this.inputRuleset && this.inputRuleset.usePasswordMask) {
        return 'a-input-password';
      }
      if (
        this.field.logic.validation.validators.find(
          validator => ['IS_FOUND', 'NOT_FOUND', 'FIND_FIRST', 'FIND_LAST'].includes(validator.comparator) || validator.references.length > 0
        )
      ) {
        return 'a-input-search';
      }

      return 'a-input';
    },
  },
};
</script>
