<template>
  <div class="details-container">
    <div class="content">
      <div v-if="!(selectedWorkflow?.events ?? []).length">
        <label>Select Blueprint:</label>
        <BlueprintSelect
          @click="openEventId()"
          @update:modelValue="blueprintId => changeWorkflowBlueprint(blueprintId)"
          :modelValue="eventBlueprintId"
          style="min-width: 100%"
        />
      </div>
      <template v-if="selectedWorkflow.selectedTrigger === 'TIMED' && timedEvent.cronTimeSelect && eventBlueprintId && !resetFilter">
        <div class="saas_card saas-schedule-card">
          <cron-light
            v-model="timedEvent.cronTimeSelect.cronExpression"
            @error="error = $event"
            style="margin-left: 10px"
            @change="updateTimedEvent"
          />
          <p style="margin-left: 10px" class="text-lightest pt-2">Cron expression: {{ timedEvent.cronTimeSelect.cronExpression }}</p>
        </div>
        <div style="margin-top: 10px; margin-bottom: 10px">
          <a-checkbox v-model:checked="timedEvent.allSubtenants" @change="updateTimedEvent"> All subtenants</a-checkbox>
        </div>
        <div style="margin-top: 10px" v-if="!timedEvent?.allSubtenants">
          <label>Select Subtenant:</label>
          <a-select
            :mode="'multiple'"
            placeholder="Select subtenant"
            v-model:value="timedEvent.cronSubtenantsIdList"
            style="width: 100%; margin-bottom: 5px"
            :options="getAppSubtenants"
            @change="updateTimedEvent"
          >
          </a-select>
        </div>
        <div>
          <FieldConditions
            v-if="selectedBlueprint && timedEvent?.filter.conditionings[0]?.conditions && !resetFilter"
            v-model="timedEvent.filter.conditionings[0].conditions"
            :useFieldManager="true"
            :fieldConditionsBlueprint="selectedBlueprint"
            :fieldConditions="timedEvent.filter.conditionings[0]?.conditions ?? []"
            :blueprints="blueprints"
            :selectedBlueprint="selectedBlueprint"
            :toFieldFromOtherBlueprint="true"
            :justReferenceFields="false"
            :autoFieldConditions="true"
            :mainBlueprint="selectedBlueprint"
            :mainSelectedBlueprint="selectedBlueprint"
            :otherBlueprint="true"
            :marginTopFromParent="'20px'"
            :showConditionsNulls="true"
            :autoHide="true"
            @setFilterState="
              value => {
                timedEvent.filter.enabled = value;
              }
            "
            @change="updateTimedEvent"
          ></FieldConditions>
          <div class="text-center pt-2 pb-2" v-else>
            <a-spin />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { CronLight } from '@vue-js-cron/light';
import { WorkflowActions } from '@/apps/workflow/shared/workflow.store';
import '@vue-js-cron/light/dist/light.css';
import BlueprintSelect from '@/apps/dataSystem/components/BlueprintSelect/BlueprintSelect.vue';
import { TenantsGetters } from '@/apps/tenants/shared/tenants.store';
import FieldConditions from '@/apps/templateManagement/views/Builder/components/referenceFieldsComponents/fieldConditions.vue';
import { blueprintApi } from '@/apps/dataSystem/api';

export default {
  props: ['workflows', 'selectedWorkflow', 'event', 'workflowId'],
  components: { FieldConditions, BlueprintSelect, CronLight },
  emits: ['openEventId'],
  data() {
    return {
      loadingEvent: null,
      eventBlueprintId: this.selectedWorkflow?.blueprint,
      timedEvent: {
        cronTimeSelect: this.selectedWorkflow?.recurrence?.cronExpression
          ? this.selectedWorkflow?.recurrence
          : {
              cronExpression: '0 0 * * *',
              type: 'noEnd',
              error: '',
            },
        cronSubtenantsIdList: null,
        filter: { conditionings: [], enabled: false },
        allSubtenants: true,
      },
      editFilter: false,
      cronLoaded: true,
      blueprints: null,
      fields: null,
      selectedBlueprint: null,
      resetFilter: false,
      loading: false,
    };
  },
  async mounted() {
    this.eventBlueprintId = this.selectedWorkflow?.blueprint;
    if (this.selectedWorkflow.selectedTrigger === 'TIMED') {
      await this.initTimed();
    }
    this.eventBlueprintId = this.selectedWorkflow?.blueprint;
    await this.loadBlueprints();
    await this.fetchBlueprint(this.eventBlueprintId);
  },
  computed: {
    getAppSubtenants() {
      const subtenants = TenantsGetters.getAllSubtenants();
      return subtenants.map(function (s) {
        return {
          label: s.name,
          value: s._id,
        };
      });
    },
  },
  methods: {
    updateTimedEvent() {
      if (!this.loading) {
        WorkflowActions.changeWorkflowTimedEvent(this.workflowId, this.timedEvent);
      }
    },
    async initTimed() {
      this.loading = true;
      // console.log(this.selectedWorkflow?.recurrence);
      if (this.selectedWorkflow.recurrence) {
        this.timedEvent.cronTimeSelect = this.selectedWorkflow?.recurrence?.cronExpression
          ? this.selectedWorkflow?.recurrence
          : {
              cronExpression: '0 0 * * *',
              type: 'noEnd',
              error: '',
            };
      }

      this.timedEvent.filter = this.selectedWorkflow?.filter ?? this.timedEvent.filter;
      if (!this.timedEvent?.filter?.conditionings) {
        this.timedEvent.filter.conditionings = [];
        this.timedEvent.filter.enabled = false;
      }
      if (!this.timedEvent?.filter?.conditionings?.length) {
        this.timedEvent.filter.conditionings.push({
          blueprintId: this.selectedBlueprintId,
          conditions: [],
        });
      }
      // check if already setted
      if (this.selectedWorkflow.allSubtenants === undefined) {
        this.timedEvent.allSubtenants = this.selectedWorkflow?.subtenantId?.length === this.getAppSubtenants.map(el => el.value)?.length;
      } else {
        this.timedEvent.allSubtenants = this.selectedWorkflow?.allSubtenants;
      }
      this.timedEvent.cronSubtenantsIdList =
        Object.keys(this.selectedWorkflow?.subtenantId ?? {})?.length !== 0
          ? this.selectedWorkflow.subtenantId
          : this.getAppSubtenants.map(el => el.value);
      this.loading = false;
    },
    async loadBlueprints() {
      this.blueprints = await blueprintApi.getAllWithFields();
    },
    async changeWorkflowBlueprint(blueprintId) {
      WorkflowActions.changeWorkflowBlueprint(this.workflowId, blueprintId);
      this.timedEvent.conditions = [];
      await this.fetchBlueprint(blueprintId);
      this.timedEvent.cronSubtenantsIdList = this.selectedWorkflow.subtenantId ?? this.getAppSubtenants.map(el => el.value);
      this.timedEvent.filter.conditionings[0] = { blueprintId, conditions: [] };
      this.resetFilter = true;
      setTimeout(() => {
        this.resetFilter = false;
      }, 10);
    },
    openEventId() {
      this.$emit('openEventId', 1);
    },
    async fetchBlueprint(blueprintId) {
      if (!blueprintId) {
        return;
      }
      const { blueprint, fieldsById } = await blueprintApi.getOne(blueprintId);
      this.selectedBlueprint = blueprint;
      this.fields = Object.values(fieldsById);
    },
  },
};
</script>
<style scoped></style>
