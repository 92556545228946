import { smsBuilder } from './views/smsList';

const smsManagementRoutes = [
  {
    path: '/',
    name: 'sms',
    component: smsBuilder,
    meta: {
      menuTitle: 'SMS List',
    },
  },
];

export default smsManagementRoutes;
