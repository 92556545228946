import { reactive } from 'vue';

const initialState = {
  spinning: false,
};

const state = reactive({ ...initialState });

const Getters = {
  getSpinning: () => {
    return state.spinning;
  },
};

const Mutations = {};

const Actions = {
  toggleSpinning(value) {
    state.spinning = value;
  },
};

export const AppGetters = Getters;
export const AppMutations = Mutations;
export const AppActions = Actions;
