import UnsubscribeEmail from './views/Unsubscribe/UnsubscribeEmail.vue';

const unsubscribeRoutes = [
  {
    path: '/email/:token/:email?',
    name: 'unsubscribe',
    component: UnsubscribeEmail,
    props: true,
    meta: {
      isPublicPage: true,
    },
  },
];

export default unsubscribeRoutes;
