<template>
  <div>
    <div>
      If response for previous event <b>{{ isComparisonPrevEvent ? 'Comparison has:' : 'Validation is:' }}</b>
      <span style="padding-left: 10px">
        <a-radio-group v-model:value="localEvent.catch.status" button-style="solid">
          <a-radio-button :value="true">{{ isComparisonPrevEvent ? 'Data returned' : 'True' }}</a-radio-button>
          <a-radio-button :value="false">{{ isComparisonPrevEvent ? 'No data returned' : 'False' }}</a-radio-button>
        </a-radio-group>
      </span>
    </div>
    <div v-if="localEvent.catch.status != null" style="padding-top: 10px">
      <a-row :gutter="18">
        <a-col class="gutter-row" :span="12">
          <a-select
            :value="localEvent.catch.workflow == null ? undefined : localEvent.catch.workflow"
            @change="selectWorkflow"
            placeholder="Select a workflow to jump"
            :filter-option="filterOption"
            style="width: 100%"
            allowClear
          >
            <a-select-option
              :label="workflow.name"
              :disabled="!workflow.activ"
              v-for="workflow in workflows"
              :key="workflow._id"
              :value="workflow._id"
            >
              <span role="img" :aria-label="workflow.name">
                <CheckCircleOutlined :style="workflow.activ ? 'color:#52c41a' : ''" v-if="workflow.activ" />
                <ExclamationCircleOutlined :style="workflow.activ ? 'color:#52c41a' : ''" v-else />
              </span>
              {{ workflow.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="12">
          <a-select
            :value="localEvent.catch.event == null ? undefined : localEvent.catch.event"
            @change="selectWorkflowEvent"
            v-if="localEvent.catch.workflow"
            show-search
            placeholder="Select an event"
            style="width: 100%"
            :filter-option="filterOption"
            allowClear
          >
            <a-select-option v-for="(showEvent, key) in showEvents" :key="showEvent._id" :value="showEvent._id">
              {{ key + 1 }}. {{ showEvent.name }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { WorkflowActions } from '@workflow/shared/workflow.store';

export default {
  name: 'workflowBuilderCatchFeedbackIndex',
  props: ['workflows', 'selectedWorkflow', 'event', 'eventList', 'prevEvent'],
  components: {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
  },
  data() {
    return {
      localEvent: { ...this.event },
    };
  },
  watch: {
    async localEvent(data) {
      await WorkflowActions.editOneEvent(this.selectedWorkflow._id, this.event._id, { ...data });
    },
  },
  computed: {
    isComparisonPrevEvent() {
      return !!this.prevEvent?.comparisons?.blueprint;
    },
    showEvents() {
      const workflows = this.workflows.filter(event => event._id === this.event.catch.workflow);
      return workflows.length ? workflows[0].events : [];
    },
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    selectWorkflow(key) {
      this.localEvent.catch.workflow = key;
    },
    selectWorkflowEvent(key) {
      this.localEvent.catch.event = key;
    },
  },
};
</script>
