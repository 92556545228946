<template>
  <div>
    <div class="dataRow">
      <div class="cell w120">
        <a-select v-model:value="updatedValue.method" style="width: 100%; display: block">
          <a-select-option value="get">GET</a-select-option>
          <a-select-option value="post">POST</a-select-option>
        </a-select>
      </div>
      <div class="cell pl-15">
        <a-input style="top: 1px" v-model:value="updatedValue.url" placeholder="ex.: https://www.example.com" />
      </div>
      <div class="cell pl-15" style="width: 250px">
        <a-select v-model:value="updatedValue.dataType" style="width: 100%; display: block">
          <a-select-option value="JSON">JSON</a-select-option>
          <a-select-option value="URLENCODED">X-WWW-FORM-URLENCODED</a-select-option>
          <a-select-option value="XML">XML</a-select-option>
        </a-select>
      </div>
    </div>

    <div v-if="updatedValue.method === 'get'" class="pt-3"><a-switch v-model:checked="updatedValue.openInTab" size="small" /> Open in tab</div>

    <template v-if="updatedValue.dataType === 'XML'">
      <p style="margin-top: 15px">How do you want to handle values from recurrent tags?</p>
      <a-radio-group v-model:value="updatedValue.dataParseType" defaultValue="xml_recurrent_cardesian">
        <a-radio :style="{ display: 'block', height: '30px', lineHeight: '30px' }" value="xml_recurrent_cardesian">
          Create all possible entities using the values of recurrent tags
        </a-radio>
        <a-radio :style="{ display: 'block', height: '30px', lineHeight: '30px' }" value="xml_recurrent_list">
          Values of recurrent tags should be saved as a list / collection
        </a-radio>
      </a-radio-group>
    </template>

    <div class="mt-10 clear">
      <a-button style="float: right" @click="hideOptions()" type="link">
        <CaretDownOutlined v-if="hide" />
        <CaretUpOutlined v-else />
        <span style="padding-right: 5px" v-html="hide ? 'Show' : 'Hide'"></span>
        options
      </a-button>
    </div>

    <div class="saas_card padd-10" v-if="!hide">
      <div class="padd-10">
        <a-button-group>
          <a-button :type="tab === 'query' ? 'primary' : ''" @click="tab = 'query'"> URL Params </a-button>
          <a-button :type="tab === 'headers' ? 'primary' : ''" @click="tab = 'headers'"> HTTP Headers </a-button>
          <a-button :type="tab === 'body' ? 'primary' : ''" @click="tab = 'body'"> Request Body </a-button>
        </a-button-group>

        <div class="options-content">
          <template v-if="tab === 'body'">
            <div class="row mb-3" v-for="(body, index) in updatedValue.body" :key="'body_' + index">
              <div class="col-3">
                <a-input v-model:value="body.key" placeholder="Key" />
              </div>
              <div :class="'col-3'">
                <a-input v-model:value="body.value" placeholder="Value" />
              </div>
              <div :class="body.keyValue === 'return_stored_data' ? 'col-2' : 'col-5'">
                <a-select
                  v-model:value="body.keyValue"
                  :default-value="body.keyValue ?? undefined"
                  :allowClear="true"
                  placeholder="Key"
                  style="width: 100%"
                  @change="changeParams"
                >
                  <a-select-option :value="'return_stored_data'">Return stored data</a-select-option>
                  <a-select-opt-group label="Tenant data">
                    <a-select-option :value="tenantAppSubtenant.tenant._id">ID: {{ tenantAppSubtenant.tenant._id }}</a-select-option>
                    <a-select-option :value="tenantAppSubtenant.tenant.slug">Slug: {{ tenantAppSubtenant.tenant.slug }}</a-select-option>
                    <a-select-option :value="tenantAppSubtenant.tenant.name">Name: {{ tenantAppSubtenant.tenant.name }}</a-select-option>
                  </a-select-opt-group>
                  <a-select-opt-group label="App data">
                    <a-select-option :value="tenantAppSubtenant.app._id">ID: {{ tenantAppSubtenant.app._id }}</a-select-option>
                    <a-select-option :value="tenantAppSubtenant.app.slug">Slug: {{ tenantAppSubtenant.app.slug }}</a-select-option>
                    <a-select-option :value="tenantAppSubtenant.app.name">Name: {{ tenantAppSubtenant.app.name }}</a-select-option>
                  </a-select-opt-group>
                  <a-select-opt-group
                    v-for="(subtenant, index) in allSubtenants"
                    :key="subtenant._id + '-' + index"
                    :label="'Subtenant: ' + subtenant.name"
                  >
                    <a-select-option :value="subtenant._id">ID: {{ subtenant._id }}</a-select-option>
                    <a-select-option :value="subtenant.slug">Slug: {{ subtenant.slug }}</a-select-option>
                    <a-select-option :value="subtenant.name">Name: {{ subtenant.name }}</a-select-option>
                  </a-select-opt-group>
                </a-select>
              </div>
              <div class="col-3" v-if="body.keyValue === 'return_stored_data'">
                <a-select style="width: 100%" :title="body.otherAuth" v-model:value="body.otherAuth" :allowClear="true" placeholder="Select Auth...">
                  <template v-for="item in authList" :key="item._id">
                    <a-select-opt-group>
                      <template #label>
                        <span>
                          <PullRequestOutlined />
                          {{ item.name }}
                        </span>
                      </template>
                      <a-select-option
                        :title="generateEndpoint(item._id, item.secret)"
                        :value="'return-url-' + generateEndpoint(item._id, item.secret)"
                        >Return URL</a-select-option
                      >
                      <a-select-option style="font-size: 12px" v-if="Object.entries(item?.response?.data ?? {}).length" disabled="disabled"
                        >Response:</a-select-option
                      >
                      <a-select-option
                        v-for="[key, value] in Object.entries(item?.response?.data ?? {})"
                        :key="'response-' + key"
                        :value="'auth-response-' + item._id + '-' + key"
                        :title="value"
                        >{{ key }} (ex.: {{ value }})</a-select-option
                      >
                      <a-select-option style="font-size: 12px" v-if="Object.entries(item?.returnResponse?.data ?? {}).length" disabled="disabled"
                        >Return Response:</a-select-option
                      >
                      <a-select-option
                        v-for="[key, value] in Object.entries(item?.returnResponse?.data ?? {})"
                        :key="'returnResponse-' + key"
                        :value="'auth-return-response-' + item._id + '-' + key"
                        :title="value"
                        >{{ key }} (ex.: {{ value }})</a-select-option
                      >
                    </a-select-opt-group>
                  </template>
                </a-select>
              </div>
              <div class="col-1">
                <a-button v-if="index > 0" @click="remove(body)"><DeleteOutlined /> </a-button>
              </div>
            </div>
          </template>
          <template v-else-if="tab === 'headers'">
            <div class="row mb-3" v-for="(header, index) in updatedValue.headers" :key="'header_' + index">
              <div class="col-3">
                <a-input v-model:value="header.key" placeholder="Key" />
              </div>
              <div :class="'col-3'">
                <a-input v-model:value="header.value" placeholder="Value" />
              </div>
              <div :class="header.keyValue === 'return_stored_data' ? 'col-2' : 'col-5'">
                <a-select
                  v-model:value="header.keyValue"
                  :default-value="header.keyValue ?? undefined"
                  :allowClear="true"
                  placeholder="Key"
                  style="width: 100%"
                  @change="changeParams"
                >
                  <a-select-option :value="'return_stored_data'">Return stored data</a-select-option>
                  <a-select-opt-group label="Tenant data">
                    <a-select-option :value="tenantAppSubtenant.tenant._id">ID: {{ tenantAppSubtenant.tenant._id }}</a-select-option>
                    <a-select-option :value="tenantAppSubtenant.tenant.slug">Slug: {{ tenantAppSubtenant.tenant.slug }}</a-select-option>
                    <a-select-option :value="tenantAppSubtenant.tenant.name">Name: {{ tenantAppSubtenant.tenant.name }}</a-select-option>
                  </a-select-opt-group>
                  <a-select-opt-group label="App data">
                    <a-select-option :value="tenantAppSubtenant.app._id">ID: {{ tenantAppSubtenant.app._id }}</a-select-option>
                    <a-select-option :value="tenantAppSubtenant.app.slug">Slug: {{ tenantAppSubtenant.app.slug }}</a-select-option>
                    <a-select-option :value="tenantAppSubtenant.app.name">Name: {{ tenantAppSubtenant.app.name }}</a-select-option>
                  </a-select-opt-group>
                  <a-select-opt-group
                    v-for="(subtenant, index) in allSubtenants"
                    :key="subtenant._id + '-' + index"
                    :label="'Subtenant: ' + subtenant.name"
                  >
                    <a-select-option :value="subtenant._id">ID: {{ subtenant._id }}</a-select-option>
                    <a-select-option :value="subtenant.slug">Slug: {{ subtenant.slug }}</a-select-option>
                    <a-select-option :value="subtenant.name">Name: {{ subtenant.name }}</a-select-option>
                  </a-select-opt-group>
                </a-select>
              </div>
              <div class="col-3" v-if="header.keyValue === 'return_stored_data'">
                <a-select
                  style="width: 100%"
                  :title="header.otherAuth"
                  v-model:value="header.otherAuth"
                  :allowClear="true"
                  placeholder="Select Auth..."
                >
                  <template v-for="item in authList" :key="item._id">
                    <a-select-opt-group>
                      <template #label>
                        <span>
                          <PullRequestOutlined />
                          {{ item.name }}
                        </span>
                      </template>
                      <a-select-option
                        :title="generateEndpoint(item._id, item.secret)"
                        :value="'return-url-' + generateEndpoint(item._id, item.secret)"
                        >Return URL</a-select-option
                      >
                      <a-select-option style="font-size: 12px" v-if="Object.entries(item?.response?.data ?? {}).length" disabled="disabled"
                        >Response:</a-select-option
                      >
                      <a-select-option
                        v-for="[key, value] in Object.entries(item?.response?.data ?? {})"
                        :key="'response-' + key"
                        :value="'auth-response-' + item._id + '-' + key"
                        :title="value"
                        >{{ key }} (ex.: {{ value }})</a-select-option
                      >
                      <a-select-option style="font-size: 12px" v-if="Object.entries(item?.returnResponse?.data ?? {}).length" disabled="disabled"
                        >Return Response:</a-select-option
                      >
                      <a-select-option
                        v-for="[key, value] in Object.entries(item?.returnResponse?.data ?? {})"
                        :key="'returnResponse-' + key"
                        :value="'auth-return-response-' + item._id + '-' + key"
                        :title="value"
                        >{{ key }} (ex.: {{ value }})</a-select-option
                      >
                    </a-select-opt-group>
                  </template>
                </a-select>
              </div>
              <div class="col-1">
                <a-button v-if="index > 0" @click="remove(header)"><DeleteOutlined /> </a-button>
              </div>
            </div>
          </template>
          <template v-else-if="tab === 'query'">
            <div class="row mb-3" v-for="(query, index) in updatedValue.query" :key="'query_' + index">
              <div class="col-3">
                <a-input v-model:value="query.key" placeholder="Key" />
              </div>
              <div :class="'col-3'">
                <a-input v-model:value="query.value" placeholder="Value" />
              </div>
              <div :class="query.keyValue === 'return_stored_data' ? 'col-2' : 'col-5'">
                <a-select
                  v-model:value="query.keyValue"
                  :default-value="query.keyValue ?? undefined"
                  :allowClear="true"
                  placeholder="Key"
                  style="width: 100%"
                  @change="changeParams"
                >
                  <a-select-option :value="'return_stored_data'">Return stored data</a-select-option>
                  <a-select-opt-group label="Tenant data">
                    <a-select-option :value="tenantAppSubtenant.tenant._id">ID: {{ tenantAppSubtenant.tenant._id }}</a-select-option>
                    <a-select-option :value="tenantAppSubtenant.tenant.slug">Slug: {{ tenantAppSubtenant.tenant.slug }}</a-select-option>
                    <a-select-option :value="tenantAppSubtenant.tenant.name">Name: {{ tenantAppSubtenant.tenant.name }}</a-select-option>
                  </a-select-opt-group>
                  <a-select-opt-group label="App data">
                    <a-select-option :value="tenantAppSubtenant.app._id">ID: {{ tenantAppSubtenant.app._id }}</a-select-option>
                    <a-select-option :value="tenantAppSubtenant.app.slug">Slug: {{ tenantAppSubtenant.app.slug }}</a-select-option>
                    <a-select-option :value="tenantAppSubtenant.app.name">Name: {{ tenantAppSubtenant.app.name }}</a-select-option>
                  </a-select-opt-group>
                  <a-select-opt-group
                    v-for="(subtenant, index) in allSubtenants"
                    :key="subtenant._id + '-' + index"
                    :label="'Subtenant: ' + subtenant.name"
                  >
                    <a-select-option :value="subtenant._id">ID: {{ subtenant._id }}</a-select-option>
                    <a-select-option :value="subtenant.slug">Slug: {{ subtenant.slug }}</a-select-option>
                    <a-select-option :value="subtenant.name">Name: {{ subtenant.name }}</a-select-option>
                  </a-select-opt-group>
                </a-select>
              </div>
              <div class="col-3" v-if="query.keyValue === 'return_stored_data'">
                <a-select
                  style="width: 100%"
                  :title="query.otherAuth"
                  v-model:value="query.otherAuth"
                  :allowClear="true"
                  placeholder="Select Auth..."
                >
                  <template v-for="item in authList" :key="item._id">
                    <a-select-opt-group>
                      <template #label>
                        <span>
                          <PullRequestOutlined />
                          {{ item.name }}
                        </span>
                      </template>
                      <a-select-option
                        :title="generateEndpoint(item._id, item.secret)"
                        :value="'return-url-' + generateEndpoint(item._id, item.secret)"
                        >Return URL</a-select-option
                      >
                      <a-select-option style="font-size: 12px" v-if="Object.entries(item?.response?.data ?? {}).length" disabled="disabled"
                        >Response:</a-select-option
                      >
                      <a-select-option
                        v-for="[key, value] in Object.entries(item?.response?.data ?? {})"
                        :key="'response-' + key"
                        :value="'auth-response-' + item._id + '-' + key"
                        :title="value"
                        >{{ key }} (ex.: {{ value }})</a-select-option
                      >
                      <a-select-option style="font-size: 12px" v-if="Object.entries(item?.returnResponse?.data ?? {}).length" disabled="disabled"
                        >Return Response:</a-select-option
                      >
                      <a-select-option
                        v-for="[key, value] in Object.entries(item?.returnResponse?.data ?? {})"
                        :key="'returnResponse-' + key"
                        :value="'auth-return-response-' + item._id + '-' + key"
                        :title="value"
                        >{{ key }} (ex.: {{ value }})</a-select-option
                      >
                    </a-select-opt-group>
                  </template>
                </a-select>
              </div>
              <div class="col-1">
                <a-button v-if="index > 0" @click="remove(query)"><DeleteOutlined /> </a-button>
              </div>
            </div>
          </template>
          <a-button @click="add()"><PlusOutlined /> Add more</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CaretDownOutlined, CaretUpOutlined, DeleteOutlined, PlusOutlined, PullRequestOutlined } from '@ant-design/icons-vue';
import { DataAuthRestActions, DataAuthRestGetters } from '@/apps/dataAuthRest/shared/dataAuthRest.store';

export default {
  name: 'SubscriptionForm',
  props: ['modelValue', 'tenantAppSubtenant', 'allSubtenants'],
  emits: ['update:modelValue'],
  data() {
    return {
      loading: false,
      tab: 'body',
      hide: true,

      updatedValue: {
        method: 'GET',
        openInTab: false,
        url: null,
        dataType: 'JSON',
        dataParseType: null,

        // dataAlwaysNew: true,
        body: [{ key: undefined, value: undefined, keyValue: undefined, otherAuth: undefined }],
        headers: [{ key: undefined, value: undefined, keyValue: undefined, otherAuth: undefined }],
        query: [{ key: undefined, value: undefined, keyValue: undefined, otherAuth: undefined }],
      },
    };
  },
  computed: {
    dataAuthRestFullList() {
      return DataAuthRestGetters.getDataAuthRestFullList();
    },
    authList() {
      return this.dataAuthRestFullList.map(item => {
        if (!item.mainRequest) {
          let { name } = item;
          if (item.parentDataAuthRestId) {
            const authItem = this.dataAuthRestFullList.find(a => a._id === item.parentDataAuthRestId);
            if (authItem) {
              name = `${authItem.name} -> ${item.name}`;
            }
          }
          return { ...item, name };
        }
        return item;
      });
    },
  },
  watch: {
    updatedValue: {
      deep: true,
      handler() {
        const body = this.updatedValue?.body[0]?.key
          ? this.updatedValue.body
          : [{ key: undefined, value: undefined, keyValue: undefined, otherAuth: undefined }];
        const headers = this.updatedValue?.headers[0]?.key
          ? this.updatedValue.headers
          : [{ key: undefined, value: undefined, keyValue: undefined, otherAuth: undefined }];
        const query = this.updatedValue?.query[0]?.key
          ? this.updatedValue.query
          : [{ key: undefined, value: undefined, keyValue: undefined, otherAuth: undefined }];

        this.$emit('update:modelValue', {
          method: this.updatedValue.method,
          url: this.updatedValue.url,
          openInTab: this.updatedValue.openInTab,
          dataType: this.updatedValue.dataType,
          dataParseType: this.updatedValue.dataParseType,
          body,
          headers,
          query,
        });
      },
    },
  },

  async mounted() {
    if (this.modelValue) {
      this.updatedValue = this.modelValue;
    }
  },

  methods: {
    generateEndpoint(_id, secret) {
      return `${process.env.VUE_APP_BASE_URL}/public/${this.tenantAppSubtenant.tenant?.slug}/${this.tenantAppSubtenant.app?.slug}/data-auth-rest/endpoint/${_id}/${secret}`;
    },
    async changeParams(value) {
      if (value === 'return_stored_data') {
        await DataAuthRestActions.fetchFullList();
      }
    },
    hideOptions() {
      this.hide = !this.hide;
    },
    add() {
      this.updatedValue[this.tab].push({
        key: undefined,
        value: undefined,
        keyValue: undefined,
        otherAuth: undefined,
      });
    },
    remove(row) {
      const key = this.updatedValue[this.tab].indexOf(row);
      this.updatedValue[this.tab] = this.updatedValue[this.tab].filter(r => this.updatedValue[this.tab].indexOf(r) !== key);
    },
  },
  components: {
    CaretDownOutlined,
    CaretUpOutlined,
    DeleteOutlined,
    PlusOutlined,
    PullRequestOutlined,
  },
};
</script>
<style scoped>
.dataRow {
  position: relative;
  display: table;
  width: 100%;
}
.dataRow .cell {
  display: table-cell;
}
.dataRow .w120 {
  width: 120px;
}
.dataRow .cell.pl-15 {
  padding-left: 15px;
}

.options-content {
  padding: 15px 10px 10px 10px;
  border: 1px solid #ddd;
  border-radius: 0 4px 4px 4px;
  margin-top: -5px;
  background-color: #f1f4f5;
}
.mt-10 {
  margin-top: 10px;
}
.clear::after {
  content: '';
  width: 100%;
  display: block;
  clear: both;
}
</style>
