import { reactive } from 'vue';

import { smsManagementApi } from '../api';
import { serializeSmsList } from '../serializers/serializer';

const initialState = {
  sms: {},
};

const state = reactive({ ...initialState });

const Getters = {
  getAll: () => state.sms,
};

const Mutations = {
  SET_SMS_LIST: smsList => {
    state.sms = smsList;
  },
};

const Actions = {
  fetchSmsList: async (page = 1, limit = 10, sorters = {}, filters = {}) => {
    const smsList = await smsManagementApi.findAllForSubtenant(page, limit, sorters, filters);

    Mutations.SET_SMS_LIST(serializeSmsList(smsList));
  },
  fetchForInstance: async (instanceId, page = 1, limit = 10, sorters = {}, filters = {}) => {
    const smsList = await smsManagementApi.findAllForInstance(instanceId, page, limit, sorters, filters);

    Mutations.SET_SMS_LIST(serializeSmsList(smsList));
  },
};

export const SmsGetters = Getters;
export const SmsMutations = Mutations;
export const SmsActions = Actions;
