<template>
  <div>
    <div style="margin: 10px 0px">
      Format
      <a-select style="width: 200px" :default-value="inputFormat" @change="change">
        <a-select-option v-for="format in DATE_FORMATS" :key="format" :value="format">
          {{ format }}
        </a-select-option>
      </a-select>
    </div>

    <div style="margin: 10px 0px">
      <a-checkbox :checked="input.useCurrentDateAsDefault" @change="toggleCurrentDateAsDefault">Use current date as default value.</a-checkbox>
    </div>

    <div v-if="!input.useCurrentDateAsDefault" style="margin: 10px 0px">
      <label>Default Value</label>
      <a-date-picker
        :format="inputFormat"
        :valueFormat="inputFormat"
        v-model:value="input.defaultValue"
        style="width: 100%"
        @change="changeDefaultValue"
      />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import 'dayjs/locale/ro';

import FieldInputOptionsMixin from '../../FieldInputOptionsMixin';

const DATE_FORMATS = ['DD.MM.YYYY', 'DD-MM-YYYY', 'YYYY-MM-DD', 'MM-DD-YYYY'];

export default {
  mixins: [FieldInputOptionsMixin],
  fieldInputOptions: {
    path: 'structure.options',
    keys: ['format', 'useCurrentDateAsDefault', 'defaultValue'],
  },
  data() {
    return {
      DATE_FORMATS,
    };
  },
  computed: {
    inputFormat() {
      return this.input?.options?.format ?? this.input?.format ?? 'DD.MM.YYYY';
    },
  },
  methods: {
    change(value) {
      const oldFormat = this.input?.options?.format ? this.input?.options?.format : this.input.format;
      this.input.defaultValue = dayjs(this.input.defaultValue, oldFormat).format(value);

      if (this.input?.options?.format) {
        this.input.options.format = value;
      } else {
        this.input.format = value;
      }
    },
    changeDefaultValue(value) {
      this.input.defaultValue = value;
    },
    toggleCurrentDateAsDefault(value) {
      this.input.useCurrentDateAsDefault = value.target.checked;
    },
  },
};
</script>

<style></style>
