<template>
  <div>
    <div>
      Html template
      <a-button size="small" class="mb-2" @click="openTokenModal()">Use fields tokens</a-button><br />
    </div>
    <Codemirror
      :model-value="initialValue"
      :options="{
        btabSize: 4,
        mode: 'text/html',
        theme: 'base16-dark',
        lineNumbers: true,
        line: true,
      }"
      :extensions="extensions"
      @change="value => emitChangedValue(value)"
    />
  </div>

  <teleport to="body">
    <a-modal v-model:open="tokenModal.visible" :title="'Tokens'" :footer="null" :width="1000" @cancel="closeTokenModal">
      <a-divider />
      <div v-if="tokenModal.visible && tokensFields">
        <template v-for="b in tokensFields" :key="b.name">
          <h4>
            {{ b.name }}
          </h4>
          <template v-for="field in b.fields" :key="field._id">
            <a-button size="small" @click="copyURL(field, field.blueprintName)" style="margin: 0 5px 5px 0">
              <span
                style="
                  margin-right: 10px;
                  padding-right: 10px;
                  margin-left: -7px;
                  padding-left: 10px;
                  background-color: #f5f5f5;
                  border-radius: 4px 0 0 4px;
                  height: 22px;
                  border-right: 1px solid #ddd;
                "
                >{{ field.structure.type }}</span
              >
              {{ generateToken(field, field.blueprintName) }}
              <CopyOutlined />
            </a-button>
          </template>
        </template>
      </div>
    </a-modal>
  </teleport>
</template>

<script>
import { Codemirror } from 'vue-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { oneDark } from '@codemirror/theme-one-dark';
import { slugify } from '@/core/utils/string-manipulation';
import { CopyOutlined } from '@ant-design/icons-vue';

export default {
  name: 'dotJsHtml',
  props: ['initialValue', 'blueprintIdList', 'blueprints'],
  components: {
    CopyOutlined,
    Codemirror,
  },
  data() {
    return {
      extensions: null,

      tokenModal: {
        visible: false,
        blueprintId: null,
        toFieldId: null,
        modalName: '',
        instancesBlueprintId: null,
      },
    };
  },
  emits: ['update-value'],
  mounted() {
    this.extensions = [javascript(), oneDark];
  },
  computed: {
    tokensFields() {
      const arrayOfTokens = [];

      if (Array.isArray(this.blueprintIdList)) {
        this.blueprintIdList.forEach(bpId => {
          const blueprint = this.blueprints.find(b => b._id.toString() === bpId.toString());
          const fieldBlueprint = (blueprint?.fields ?? []).map(item => {
            return { blueprintName: blueprint.name, ...item };
          });
          arrayOfTokens.push({ name: blueprint.name, fields: fieldBlueprint });
        });
      }

      return arrayOfTokens;
    },
  },
  methods: {
    emitChangedValue(changedValue) {
      this.$emit('update-value', changedValue);
    },
    generateToken(field, base = '') {
      return `{{=token${base !== '' ? `?.${slugify(base)}` : ''}?.${slugify(field.label)}}}`;
    },
    copyURL(field, base = '') {
      navigator.clipboard.writeText(this.generateToken(field, base));
    },
    openTokenModal() {
      this.tokenModal.visible = true;
    },
    closeTokenModal() {
      this.tokenModal.visible = false;
    },
  },
};
</script>
