<template>
  <div id="app">
    <NavbarComponent :user="user" v-if="user" />
    <a-spin :spinning="spinning">
      <div class="container app-container">
        <router-view />
      </div>
    </a-spin>
    <FooterComponent />
  </div>
</template>

<script>
import { UserGetters } from '@userManagement/user.store';
import { AppGetters } from '@/App.store';
import NavbarComponent from './apps/common/views/Header/Navbar.vue';
import FooterComponent from './apps/common/views/Footer/Footer.vue';

export default {
  data() {
    return {
      isLoading: true,
    };
  },
  components: {
    NavbarComponent,
    FooterComponent,
  },
  computed: {
    spinning: AppGetters.getSpinning,
    user: UserGetters.getUser,
  },
};
</script>
<style></style>
