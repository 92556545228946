import { reactive } from 'vue';

const initialState = {
  backPage: {
    menuItemId: null,
    tabIndex: {},
    tablePageIndexByTemplateId: {},
  },
};

const state = reactive({ ...initialState });

const Getters = {
  getBackTemplate: () => {
    return state.backPage;
  },
  getBackTabIndex: uid => {
    if (uid) {
      return state.backPage.tabIndex[uid];
    }
    return null;
  },
  getBackTablePageIndex: templateId => {
    if (templateId) {
      return state.backPage.tablePageIndexByTemplateId[templateId];
    }
    return null;
  },
};

const Actions = {
  async addTablePagePosition(templateId, pageIndex) {
    if (templateId) {
      state.backPage.tablePageIndexByTemplateId[templateId] = pageIndex;
    }
  },
  async addTabPosition(uid, tabIndex) {
    if (uid) {
      state.backPage.tabIndex[uid] = tabIndex;
    }
  },
  async addMenuItemId(menuItemId) {
    if (state.backPage.menuItemId !== menuItemId) {
      state.backPage.menuItemId = menuItemId;
      state.backPage.tabIndex = {};
      state.backPage.tablePageIndexByTemplateId = {};
    }
  },
};

export const BackButtonGetters = Getters;
export const BackButtonActions = Actions;
